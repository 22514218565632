import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from 'react';
import { node } from 'prop-types';

import ThemeProvider from '../theme/ThemeProvider';
import theme from '../theme/preset';
import { OrganizationsResource } from '../services';

const propTypes = {
  children: node.isRequired,
};

export const BrandingContext = createContext({
  logo: null,
  setOrganizationLogo: () => null,
  branding: null,
});

export const useBranding = () => {
  return useContext(BrandingContext);
};

const BrandingProvider = ({ children }) => {
  const [branding, setBranding] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationLogo, setOrganizationLogo] = useState(null);
  const [organizationPlan, setOrganizationPlan] = useState(null);
  const [organizationSettings, setOrganizationSettings] = useState({
    sessionNotes: true,
    chat: true,
    requiredService: false,
    bookingIntervals: 15,
    createUser: true,
    deleteUser: true,
    apiKey: false,
    joinBeforeHost: false,
    sendFirstReminder: true,
    sendSecondReminder: true,
    firstReminder: 120,
    secondReminder: 15,
  }); // Settings default add new features to obj
  const [paymentGateway, setPaymentGateway] = useState(null);
  const [paypalStatus, setPaypalStatus] = useState('');
  const [paymentConnection, setPaymentConnection] = useState(false);
  const [paidSession, setPaidSession] = useState('');
  const [chargifyEnabled, setChargifyEnabled] = useState(false);
  const [render, setRender] = useState(false);
  const { host } = window.location;
  const subDomain = host.split('.')[0];

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      // Stripe
      const fromStripe = urlParams.get('returnFromStripe');
      // Paypal
      const fromPaypal = urlParams.get('returnFromPaypal');
      const sessionFromPaypal = urlParams.get('sessionId');

      if (sessionFromPaypal) setPaidSession(sessionFromPaypal);

      if (fromStripe || fromPaypal) setPaymentConnection(true);
      try {
        const brand = await OrganizationsResource.getBranding(subDomain);
        if (brand) {
          const {
            brandingElements,
            logo,
            plan,
            id,
            paymentGateway: gateway,
            paymentGatewayId,
            settingsFeatures,
            superuserSettings,
            paypalStatus: status,
            chargifyEnable,
          } = brand;
          setBranding(brandingElements);
          setOrganizationId(id);
          setOrganizationLogo(logo);
          setOrganizationPlan(plan);
          setOrganizationSettings((prevSettings) => ({
            ...prevSettings,
            ...settingsFeatures,
            ...superuserSettings,
          }));
          setPaymentGateway({ paymentGateway: gateway, paymentGatewayId });
          setPaypalStatus(status);
          setChargifyEnabled(chargifyEnable);
        } else {
          window.location.href = 'https://lti.trustvideo.com';
        }
      } catch (error) {
        window.location.href = 'https://lti.trustvideo.com';
      } finally {
        setRender(true);
      }
    })();
  }, [subDomain, host]);

  const organizationTheme = useMemo(() => {
    if (branding && Object.keys(branding).length) {
      return {
        ...theme,
        colors: {
          ...theme.colors,
          primary: {
            ...theme.colors.primary,
            ...(branding.secondaryColor
              ? { main: branding.secondaryColor }
              : {}),
            ...(branding.primaryButtonBackgroundColor
              ? { dark: branding.primaryButtonBackgroundColor }
              : {}),
          },
          secondary: {
            ...theme.colors.secondary,
            ...(branding.primaryColor ? { main: branding.primaryColor } : {}),
            ...(branding.secondaryButtonBackgroundColor
              ? { dark: branding.secondaryButtonBackgroundColor }
              : {}),
          },
          ...(branding.primaryButtonTextColor
            ? {
                primaryButtonTextColor: branding.primaryButtonTextColor,
              }
            : {}),
          ...(branding.primaryButtonBackgroundColor
            ? {
                primaryButtonBackgroundColor:
                  branding.primaryButtonBackgroundColor,
              }
            : {}),
          ...(branding.secondaryButtonTextColor
            ? {
                secondaryButtonTextColor: branding.secondaryButtonTextColor,
              }
            : {}),
          ...(branding.secondaryButtonBackgroundColor
            ? {
                secondaryButtonBackgroundColor:
                  branding.secondaryButtonBackgroundColor,
              }
            : {}),
          ...(branding.sidebarBackgroundColor
            ? {
                sidebarBackgroundColor: branding.sidebarBackgroundColor,
              }
            : {}),
          ...(branding.sidebarTextColor
            ? {
                sidebarTextColor: branding.sidebarTextColor,
              }
            : {}),
          ...(branding.sidebarHighlightColor
            ? {
                sidebarHighlightColor: branding.sidebarHighlightColor,
              }
            : {}),
          ...(branding.sidebarHighlightTextColor
            ? {
                sidebarHighlightTextColor: branding.sidebarHighlightTextColor,
              }
            : {}),
        },
      };
    }
    return { ...theme };
  }, [branding]);

  const context = {
    organizationTheme,
    organizationLogo,
    organizationPlan,
    organizationSettings,
    setBranding,
    organizationId,
    paymentConnection,
    paymentGateway,
    setPaymentGateway,
    setOrganizationSettings,
    setOrganizationLogo,
    paidSession,
    setPaidSession,
    paypalStatus,
    setPaypalStatus,
    chargifyEnabled,
  };

  return (
    <BrandingContext.Provider value={context}>
      <ThemeProvider theme={organizationTheme}>
        {render ? children : null}
      </ThemeProvider>
    </BrandingContext.Provider>
  );
};

BrandingProvider.propTypes = propTypes;

export default BrandingProvider;
