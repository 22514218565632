import React, { Component } from 'react';
import { node } from 'prop-types';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import AppBar from '@bit/medicalwebexperts.mwe-ui.app-bar';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Logo from '../Logo/Logo';
import { BrandingContext } from '../../contexts/BrandingContext';
import imgError from '../../assets/images/LTI-error.svg';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(
  css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: ['100vh', '90vh'],
    p: 4,
    width: '100%',
  }),
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleGoBack() {
    this.setState({
      hasError: false,
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    const { organizationLogo } = this.context;
    return hasError ? (
      <>
        <AppBar
          backgroundColor="gray.200"
          position="sticky"
          py={2}
          pr={4}
          pl={4}
          elevation={2}
        >
          <Logo logo={organizationLogo} height="50px" width="auto" pr={1} />
        </AppBar>
        <Main>
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Image src={imgError} width="100%" mb={16} />
            <Typography variant="h1" paragraph>
              Sorry, something went wrong
            </Typography>
            <Typography variant="h5" mb={10}>
              We&apos;re working hard to fix it for you as soon as possible.
            </Typography>
            <Button
              variantColor="secondary"
              startIcon={<Icon name="arrowLeft" size={5} />}
              as={Link}
              onClick={this.handleGoBack}
              to="/sessions"
            >
              Back to Dashboard
            </Button>
          </Flex>
        </Main>
      </>
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = propTypes;
ErrorBoundary.contextType = BrandingContext;

export default ErrorBoundary;
