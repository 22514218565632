import React from 'react';
import ReactDOM from 'react-dom';
import SnackbarProvider from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import AuthProvider from './contexts/AuthContext';
import BrandingProvider from './contexts/BrandingContext';
import NotificationsProvider from './contexts/NotificationsContext';
import ModalProvider from './contexts/ModalContext';
import 'typeface-open-sans';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrandingProvider>
        <SnackbarProvider>
          <NotificationsProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </NotificationsProvider>
        </SnackbarProvider>
      </BrandingProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
