import React from 'react';
import { NavLink } from 'react-router-dom';
import { array, func, oneOfType, string } from 'prop-types';
import Dropdown, {
  useDropdownState,
} from '@bit/medicalwebexperts.mwe-ui.dropdown';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Button from '@bit/medicalwebexperts.mwe-ui.button';

const propTypes = {
  name: string.isRequired,
  items: oneOfType([array]).isRequired,
  onClick: func.isRequired,
};

const buttonClasses = {
  root: {
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '0px',
    border: 'none',
    outline: 'none',
    '&:hover': {
      bg: 'sidebarHighlightColor',
      color: 'sidebarHighlightTextColor',
    },
    '&.active': {
      bg: 'sidebarHighlightColor',
      color: 'sidebarHighlightTextColor',
      borderRightColor: 'sidebarHighlightColor',
    },
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightWidth: '6px',
    color: 'sidebarTextColor',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'md',
    fontWeight: 'medium',
    py: 6,
  },
};

const dropdownItemClasses = {
  root: {
    color: 'sidebarTextColor',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'md',
    fontWeight: 'medium',
  },
};

const menuClasses = {
  root: {
    border: '1px solid',
    borderColor: 'gray.400',
    bg: 'sidebarDropdown',
  },
};

const SidebarItemDropdown = ({ name, items, onClick }) => {
  const dropdown = useDropdownState();

  const handleClick = () => {
    dropdown.hide();
    onClick();
  };

  return (
    <Dropdown state={dropdown}>
      <Dropdown.Toggle
        render={(toggleProps) => (
          <Button
            variant="text"
            endIcon={<Icon name="arrowDown" size={6} />}
            {...toggleProps}
            classes={buttonClasses}
          >
            {name}
          </Button>
        )}
      />
      <Dropdown.Menu
        aria-label="Dropdown menu"
        width="100%"
        classes={menuClasses}
      >
        <Flex flexDirection="column">
          {items &&
            items.map((item) => (
              <Dropdown.Item
                onClick={handleClick}
                key={item.to}
                as={NavLink}
                to={item.to}
                exact
                classes={dropdownItemClasses}
              >
                {item.label}
              </Dropdown.Item>
            ))}
        </Flex>
      </Dropdown.Menu>
    </Dropdown>
  );
};

SidebarItemDropdown.propTypes = propTypes;
export default SidebarItemDropdown;
