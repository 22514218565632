import Resource from './base';

const baseUrl = '/api/booking';

const actions = (client) => ({
  getServiceGroups(params) {
    return client.get(`${baseUrl}/service_groups`, { params });
  },

  getServices(params) {
    return client.get(`${baseUrl}/services`, { params });
  },

  getProvidersByService(params) {
    return client.get(`${baseUrl}/providers`, { params });
  },

  signup(data) {
    return client.post('/api/meeting_requests_participant/signup', data);
  },
});

export default Resource(baseUrl, actions);
