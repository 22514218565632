import axios from 'axios';
import Cookies from 'js-cookie';
import rootConfig from '../config';

const createClient = (configs) => {
  const {
    prefixToken = 'Bearer',
    tokenName = 'access',
    refreshTokenName = 'refresh',
    storage = Cookies,
    requestInterceptors = () => [],
    responseInterceptors = () => [],
    ...axiosConfigs
  } = configs;
  const http = axios.create(axiosConfigs);
  const setToken = (token) => {
    http.defaults.headers.common.Authorization = `${prefixToken} ${token}`;
  };

  const location = window.location.hostname.split('.');
  location.shift();
  const domain = location.join('.');

  http.$getToken = () => storage.get(tokenName, { domain });
  http.$getRefreshToken = () => storage.get(refreshTokenName, { domain });

  http.$setToken = (token, refresh) => {
    setToken(token);
    storage.set(tokenName, token, { domain, expires: 3650 });
    if (refresh)
      storage.set(refreshTokenName, refresh, { domain, expires: 3650 });
  };

  http.$clearToken = () => {
    delete http.defaults.headers.common.Authorization;
    storage.remove(tokenName, { domain });
    storage.remove(refreshTokenName, { domain });
  };

  http.$refreshToken = (e) => {
    const originalRequest = e.config;
    if (
      e.response &&
      e.response.status &&
      e.response.status === 401 &&
      originalRequest.retryRefresh
    ) {
      http.$clearToken();
      return Promise.reject(e);
    }

    if (
      e.response &&
      e.response.status &&
      e.response.status === 401 &&
      !originalRequest.retryRefresh
    ) {
      originalRequest.retryRefresh = true;
      const refresh = http.$getRefreshToken();

      if (refresh) {
        return axios
          .post(`${rootConfig.http.baseURL}/api/token/refresh`, {
            refresh,
          })
          .then((res) => {
            if (res.status === 200) {
              originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
              http.$setToken(res.data.access, null);
              return http(originalRequest);
            }
            return Promise.reject(e);
          });
      }
    }
    return Promise.reject(e);
  };

  requestInterceptors(http).forEach((interceptor) =>
    http.interceptors.request.use(...interceptor),
  );
  responseInterceptors(http).forEach((interceptor) =>
    http.interceptors.response.use(...interceptor),
  );

  if (http.$getToken()) {
    setToken(http.$getToken());
  }

  return http;
};

const client = createClient({
  ...rootConfig.http,
  responseInterceptors(http) {
    return [
      [
        (response) => {
          const { data } = response;
          return data;
        },
        (error) => {
          if (error && error.response && error.response.status === 401) {
            return http.$refreshToken(error);
          }
          return Promise.reject(error);
        },
      ],
    ];
  },
});

export default client;
