import React, { useState, useContext, createContext, useCallback } from 'react';
import { node } from 'prop-types';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import { useAuth } from './AuthContext';
import { DocumentsResource, SessionsResource } from '../services';
import snackbarTimeout from '../constants/snackbarTimer';

const propTypes = {
  children: node.isRequired,
};

export const ClientFilingContext = createContext({
  upcomingSessions: [],
  pastSessions: [],
  submissions: [],
  setUser: () => null,
  setIsContact: () => null,
  loading: true,
});

export const useClientFiling = () => {
  return useContext(ClientFilingContext);
};
const ClientFilingProvider = ({ children }) => {
  const { organizationId } = useAuth();
  const snackbar = useSnackbarDispatch();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [isContact, setIsContact] = useState(false);
  const [host, setHost] = useState(null);

  const getUpcomingSessions = useCallback(async () => {
    try {
      if (user) {
        const results = await SessionsResource.getParticipantMeetingsUsage({
          orgId: organizationId,
          userId: user.id,
          isContact,
          start_time__gte: momentTimezone(moment(new Date()))
            .tz(user.timezone)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          host,
        });
        setUpcomingSessions(results);
      }
    } catch (error) {
      snackbar.open({
        message: `Sorry, there was an error fetching Sessions: ${error.message}. Please, try later.`,
        status: 'error',
        autoHideDuration: snackbarTimeout,
      });
    } finally {
      setLoading(false);
    }
  }, [organizationId, snackbar, setUpcomingSessions, user, isContact, host]);

  const getDocuments = useCallback(async () => {
    try {
      if (user) {
        const payload = {
          organization: organizationId,
        };
        if (!isContact) {
          payload.user = user.id;
        } else {
          payload.contact = user.id;
        }
        if (host) {
          payload.host = host;
        }
        const results = await DocumentsResource.getClientFilingSubmission(
          payload,
        );
        setSubmissions(results);
      }
    } catch (error) {
      snackbar.open({
        message: `Sorry, there was an error fetching Documents: ${error.message}. Please, try later.`,
        status: 'error',
        autoHideDuration: snackbarTimeout,
      });
    } finally {
      setLoading(false);
    }
  }, [user, organizationId, isContact, host, snackbar]);

  const getPastSessions = useCallback(async () => {
    try {
      if (user) {
        const results = await SessionsResource.getParticipantMeetingsUsage({
          orgId: organizationId,
          userId: user.id,
          isContact,
          start_time__lt: momentTimezone(moment(new Date()))
            .tz(user.timezone)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
          hostId: host,
        });
        setPastSessions(results);
      }
    } catch (error) {
      snackbar.open({
        message: `Sorry, there was an error fetching Sessions: ${error.message}. Please, try later.`,
        status: 'error',
        autoHideDuration: snackbarTimeout,
      });
    } finally {
      setLoading(false);
    }
  }, [organizationId, snackbar, setPastSessions, user, isContact, host]);

  const context = {
    getUpcomingSessions,
    getDocuments,
    upcomingSessions,
    getPastSessions,
    pastSessions,
    setUser,
    user,
    setIsContact,
    loading,
    submissions,
    host,
    setHost,
  };

  return (
    <ClientFilingContext.Provider value={context}>
      {children}
    </ClientFilingContext.Provider>
  );
};

ClientFilingProvider.propTypes = propTypes;

export default ClientFilingProvider;
