import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import AppBar from '@bit/medicalwebexperts.mwe-ui.app-bar';
import AsyncContactsSelect from '../../AsyncContactsSelect/AsyncContactsSelect';
import ProfileDropdown from '../../ProfileDropdown/ProfileDropdown';
import { useAuth } from '../../../contexts/AuthContext';
import getUserRole from '../../../utils/getUserRole';

const Toolbar = styled.div(({ justifyContent }) =>
  css({
    display: 'flex',
    justifyContent,
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
  }),
);

const GlobalSearch = () => {
  const { user } = useAuth();
  const hideGlobalSearch =
    getUserRole(user) === 'isSessionParticipant' ||
    getUserRole(user) === 'isBillingManager' ||
    getUserRole(user) === 'isClinical' ||
    user.isTemporary;

  return (
    <AppBar backgroundColor="gray.200" position="sticky" pl="240px" py={2}>
      <Toolbar justifyContent={hideGlobalSearch ? 'flex-end' : 'space-between'}>
        {!hideGlobalSearch && <AsyncContactsSelect />}
        <ProfileDropdown />
      </Toolbar>
    </AppBar>
  );
};

export default GlobalSearch;
