import Resource from './base';

const baseUrl = '/api/meeting_notes_templates';

const actions = (client) => ({
  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  deleteTemplate(id, params) {
    return client.delete(`${baseUrl}/${id}`, {
      params,
    });
  },
});

export default Resource(baseUrl, actions);
