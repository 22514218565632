import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

import Modal, { useModalState } from '@bit/medicalwebexperts.mwe-ui.modal';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { useDown } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import { useModal } from '../../contexts/ModalContext';

const propTypes = {};
const dismissedLoc = 'dismissedDesktopModal';
const dismissedLocZoom = 'dismissedZoomModal';

const StyledText = styled(Typography)(() =>
  css({
    color: '#707070',
    fontSize: 16,
  }),
);

const StyledButton = styled(Button)(() =>
  css({
    bg: '#215371',
  }),
);

const location = window.location.hostname.split('.');
location.shift();
const domain = location.join('.');

const DesktopModal = () => {
  const isMobile = useDown('lg');
  const modalState = useModalState();
  const { setDesktopModal } = useModal();
  const evaluateDismissedModal = () => {
    const dismissedModal = Cookies.get(dismissedLoc, { domain });
    const dismissedModalZoom = Cookies.get(dismissedLocZoom, { domain });

    if (
      !dismissedModal &&
      'Notification' in window &&
      (Notification.permission === 'denied' ||
        Notification.permission === 'default')
    ) {
      modalState.show();
      setDesktopModal(isMobile);
    } else if (!dismissedModalZoom) {
      setDesktopModal(true);
    }
  };

  useEffect(evaluateDismissedModal, []);

  const handleClose = (dismiss) => {
    if (dismiss) {
      Cookies.set(dismissedLoc, 'true', { domain, expires: 3650 });
    }
    modalState.hide();
    setDesktopModal(true);
  };

  const handleEnable = () => {
    Notification.requestPermission();
    modalState.hide();
    setDesktopModal(true);
  };

  return (
    <Modal state={modalState} ariaLabel="Zoom Modal" withBackdrop>
      <Typography color="#215371" mt={6} variant="h2" textAlign="center">
        Enable Desktop Notifications?
      </Typography>
      <Modal.Body px={4}>
        <StyledText variant="body2" textAlign="center" paragraph>
          Don&apos;t miss a notification. Enabling notifications will display
          video and message notifications, even when this website is running in
          the background.
        </StyledText>
      </Modal.Body>
      <Modal.Footer>
        <Flex justifyContent="flex-end">
          <Button variant="text" onClick={() => handleClose(false)} mr={4}>
            Not Now
          </Button>
          <StyledButton onClick={() => handleClose(true)} mr={4}>
            Don&apos;t ask me again
          </StyledButton>
          <Flex flex={1} />
          <Button variantColor="secondary" onClick={handleEnable} mr={4}>
            Enable
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

DesktopModal.propTypes = propTypes;
export default DesktopModal;
