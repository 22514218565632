import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(() =>
  css({
    position: 'relative',
    minHeight: '100vh',
    backgroundColor: 'white',
  }),
);

const ApiDocumentLayout = ({ children }) => {
  return (
    <>
      <Main>{children}</Main>
    </>
  );
};

ApiDocumentLayout.propTypes = propTypes;

export default ApiDocumentLayout;
