import Resource from './base';

const baseUrl = '/api/reports';
const baseUrlMeetingUsage = '/api/reports/meetings_usage';
const baseUrlMeetingConnections = '/api/reports/meeting_connections';
const baseUrlMeetingRequests = '/api/reports/meeting_requests';
const baseUrlSystemLogins = '/api/reports/system_logins';
const baseUrlSessionHistory = '/api/reports/session_history';
const baseUrlParticipantHistory = '/api/reports/participant_history';
const baseUrlSessionNotes = '/api/meeting_notes/download_note';
const baseUrlDocumentView = '/api/submissions';

const actions = (client) => ({
  getMeetingUsage(params) {
    return client.get(`${baseUrlMeetingUsage}`, { params });
  },

  getMeetingConnections(params) {
    return client.get(`${baseUrlMeetingConnections}`, { params });
  },

  getMeetingRequests(params) {
    return client.get(`${baseUrlMeetingRequests}`, { params });
  },

  getSystemLogins(params) {
    return client.get(`${baseUrlSystemLogins}`, { params });
  },

  getSessionHistory(params) {
    return client.get(`${baseUrlSessionHistory}`, { params });
  },

  getParticipantHistory(params) {
    return client.get(`${baseUrlParticipantHistory}`, { params });
  },
  getSessionNotes(params) {
    return client.get(`${baseUrlSessionNotes}`, { params });
  },
  getDocumentView(id, params) {
    return client.get(`${baseUrlDocumentView}/${id}/download_document`, {
      params,
    });
  },
});

export default Resource(baseUrl, actions);
