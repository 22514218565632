export const groups = {
  ADMINISTRATOR: 'ADMINISTRATOR',
  BILLING_MANAGER: 'BILLING_MANAGER',
  CLINICAL_SUPERVISOR: 'CLINICAL_SUPERVISOR',
  SCHEDULER: 'SCHEDULER',
  SESSION_HOST: 'SESSION_HOST',
  SESSION_PARTICIPANT: 'SESSION_PARTICIPANT',
};

export const dispatcherLabel = 'Dispatcher';

export const groupsLabel = {
  [groups.ADMINISTRATOR]: 'Account Administrator',
  [groups.BILLING_MANAGER]: 'Billing Manager',
  [groups.CLINICAL_SUPERVISOR]: 'Clinical Supervisor',
  [groups.SCHEDULER]: 'Scheduler',
  [groups.SESSION_HOST]: 'Videoconference Session Host',
  [groups.SESSION_PARTICIPANT]: 'Videoconference Session Participant',
};

export const groupsValues = {
  [groups.ADMINISTRATOR]: 1,
  [groups.BILLING_MANAGER]: 6,
  [groups.CLINICAL_SUPERVISOR]: 5,
  [groups.SCHEDULER]: 4,
  [groups.SESSION_HOST]: 2,
  [groups.SESSION_PARTICIPANT]: 3,
};

export const groupsList = Object.keys(groupsLabel).map((value) => ({
  label: groupsLabel[value],
  value,
}));
