/* eslint-disable camelcase */
import Resource from './base';

const baseUrl = '/api/meetings';
const baseUrlReadOnly = '/api/meetings_read';
const participantsUrl = '/api/participants';

const actions = (client) => ({
  getMeeting(meetingId, params) {
    return client.get(`${baseUrl}/${meetingId}`, { params });
  },

  getMeetings(params) {
    return client.get(`${baseUrl}`, { params });
  },

  getParticipantMeetings(orgId, params) {
    return client.get(`${baseUrlReadOnly}?organization=${orgId}`, { params });
  },

  getParticipantMeetingsAccessCode({ orgId, accessCode }) {
    return client.get(
      `${baseUrlReadOnly}?organization=${orgId}&accessCode=${accessCode}`,
    );
  },

  getInvitedMeeting(orgId, params) {
    return client.get(
      `${baseUrlReadOnly}/invited_meeting?organization=${orgId}`,
      { params },
    );
  },

  getParticipantMeetingsUsage({
    orgId,
    userId,
    isContact,
    start_time__gte,
    start_time__lt,
    hostId,
  }) {
    return client.get(
      `${baseUrlReadOnly}/client_file?organization=${orgId}&${
        isContact ? 'contact' : 'user'
      }=${userId}`,
      {
        params: {
          start_time__lt: start_time__lt || undefined,
          start_time__gte: start_time__gte || undefined,
          host: hostId || undefined,
        },
      },
    );
  },

  createMeeting(data) {
    return client.post(`${baseUrl}`, data);
  },

  scheduleNextMeeting(id, data) {
    return client.post(`${baseUrl}/${id}/schedule_next`, data);
  },

  deleteMeeting(meetingId, orgId, host) {
    return client.delete(
      `${baseUrl}/${meetingId}?organization=${orgId}&host=${host}`,
    );
  },

  updateMeeting({ meetingId, data }) {
    return client.put(`${baseUrl}/${meetingId}`, data);
  },

  hideMeeting({ meetingId, data }) {
    return client.put(`${baseUrl}/${meetingId}/hide`, data);
  },

  getMeetingParticipants({ orgId, host, meetingId, offset, limit }) {
    return client.get(
      `${participantsUrl}?organization=${orgId}&host=${host}&meeting=${meetingId}`,
      {
        params: {
          offset,
          limit,
        },
      },
    );
  },

  getMeetingParticipant(participantId, orgId, host, meetingId) {
    return client.get(
      `${participantsUrl}/${participantId}?organization=${orgId}&host=${host}&meeting=${meetingId}`,
    );
  },

  addParticipant(data) {
    return client.post(`${participantsUrl}`, data);
  },

  updateParticipant(participantId, data) {
    return client.put(`${participantsUrl}/${participantId}`, data);
  },

  removeParticipant(participantId, orgId, host, meetingId) {
    return client.delete(
      `${participantsUrl}/${participantId}?organization=${orgId}&host=${host}&meeting=${meetingId}`,
    );
  },

  getAccessCode(participantId, orgId, host, meetingId) {
    return client.get(
      `${participantsUrl}/${participantId}/code?organization=${orgId}&host=${host}&meeting=${meetingId}`,
    );
  },

  getJoinUrl(participantId, orgId) {
    return client.get(
      `${participantsUrl}/${participantId}/join?organization=${orgId}`,
    );
  },

  getStartUrl(meetingId, orgId) {
    return client.get(`/api/meetings/${meetingId}/start?organization=${orgId}`);
  },

  setParticipantRSVP(participantId, data) {
    return client.post(`${participantsUrl}/${participantId}/rsvp`, data);
  },

  setInvitationUrl(meetingId, data) {
    return client.put(`${baseUrl}/${meetingId}/invitation_url`, data);
  },
  getInvitationUrl({ orgId, host, meetingId }) {
    return client.get(
      `${baseUrl}/${meetingId}/registrants?organization=${orgId}&host=${host}`,
    );
  },
  setParticipantWaitingRoomStatus(participantId, data) {
    return client.post(
      `${participantsUrl}/${participantId}/waiting_room`,
      data,
    );
  },

  remindParticipant(participantId, data) {
    return client.post(`${participantsUrl}/${participantId}/remind`, data);
  },

  hostDocument(meetingId, data) {
    return client.post(`${baseUrl}/${meetingId}/host_document`, data);
  },

  updateParticipantDocuments(participantId, data) {
    return client.post(
      `${participantsUrl}/${participantId}/update_participant_documents`,
      data,
    );
  },
});

export default Resource(baseUrl, actions);
