import React from 'react';
import { node } from 'prop-types';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import { useUp } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import BaseSidebar, {
  useSidebarState,
} from '@bit/medicalwebexperts.mwe-ui.sidebar';
import AppBar from '@bit/medicalwebexperts.mwe-ui.app-bar';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Sidebar from './Sidebar/Sidebar';
import GlobalSearch from './GlobalSearch/GlobalSearch';
import Logo from '../Logo/Logo';
import { useBranding } from '../../contexts/BrandingContext';
import getScreenName from '../../utils/getScreenName';
import RedirectModal from '../RedirectModal/RedirectModal';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(({ theme }) =>
  css({
    ml: [null, null, null, theme.sizes.sidebar],
    p: 4,
    position: 'relative',
    pt: [24, 24, 24, 8],
    minHeight: '100vh',
    backgroundColor: 'white',
  }),
);

const Toolbar = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 16,
    pl: 4,
    pr: 6,
  }),
);

const LoggedInLayout = ({ children }) => {
  const showAppBar = !useUp('lg');
  const sidebar = useSidebarState({ animated: true });
  const { organizationLogo } = useBranding();
  const { pathname } = useLocation();

  return (
    <>
      <Sidebar expand="lg" state={sidebar} ariaLabel="Main Menu" />
      {!showAppBar && <GlobalSearch />}
      <Main>
        {showAppBar && (
          <AppBar variant="primary">
            <Toolbar>
              <BaseSidebar.Disclosure {...sidebar}>
                {(props) => (
                  <IconButton size="lg" color="inherit" {...props}>
                    <Icon name="menu" size={8} />
                  </IconButton>
                )}
              </BaseSidebar.Disclosure>
              <Typography variant="h2" color="white">
                {getScreenName(pathname)}
              </Typography>
              <Logo logo={organizationLogo} width="40px" />
            </Toolbar>
          </AppBar>
        )}
        {children}
      </Main>
      <RedirectModal />
    </>
  );
};

LoggedInLayout.propTypes = propTypes;

export default LoggedInLayout;
