import Resource from './base';

const baseUrl = '/api/meeting_notes';

const actions = (client) => ({
  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  groupByUser(params) {
    return client.get('/api/user_notes', { params });
  },

  removeNote(id, params) {
    return client.delete(`${baseUrl}/${id}`, {
      params,
    });
  },

  getNoteByTaggedUser(params) {
    return client.get(`${baseUrl}/client_file`, { params });
  },
});

export default Resource(baseUrl, actions);
