import Resource from './base';

const baseUrl = '/api/users';

const actions = (client) => ({
  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  updateProfile(data) {
    return client.put(`${baseUrl}/profile`, data);
  },

  getParticipants(organization, host, name) {
    return client.get(`${baseUrl}/participants`, {
      params: {
        organization,
        host,
        name,
      },
    });
  },

  toggleEmergencyService(data) {
    return client.put(`${baseUrl}/emergency_service`, data);
  },

  updateNotifications(data) {
    return client.put(`${baseUrl}/notifications`, data);
  },

  getParticipantProviders({ orgId, isContact, userId, hostId }) {
    return client.get(
      `${baseUrl}/participant_providers?organization=${orgId}&${
        isContact ? 'contact' : 'user'
      }=${userId}&host=${hostId}`,
    );
  },

  getUser(userId, params) {
    return client.get(`${baseUrl}/${userId}/participant`, { params });
  },

  deactivateUser(id, orgId) {
    return client.delete(`${baseUrl}/${id}?organization=${orgId}`);
  },

  updateHostsQuantityPreview(orgId) {
    return client.get(
      `${baseUrl}/hosts_quantity_preview?organization=${orgId}`,
    );
  },

  getReadOnlyMembers(params) {
    return client.get(`${baseUrl}/members`, {
      params,
    });
  },
});

export default Resource(baseUrl, actions);
