import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { node } from 'prop-types';
import PubNub from 'pubnub';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import { useModalState } from '@bit/medicalwebexperts.mwe-ui.modal';
import { useAuth } from './AuthContext';
import { groups } from '../constants/groups';
import { RequestsResource } from '../services';
import useRequestsFetch from '../hooks/requestsFetch';
import useChat from '../hooks/chat';
import chatSound from '../assets/sounds/alert_simple.mp3';
import notificationSound from '../assets/sounds/notification_simple.mp3';

const propTypes = {
  children: node.isRequired,
};

export const NotificationContext = createContext({
  unreadChats: null,
  setUnreadChats: null,
  pubnub: null,
  fetchSubscribedChats: null,
  chats: null,
  activeChat: null,
  activeChatData: null,
  messages: null,
  loadingMessages: null,
  dispatchChats: null,
  setActiveChat: null,
  loadingChats: null,
});

const chatLocatorPath = 'chat';

const NotificationsProvider = ({ children }) => {
  const {
    user,
    organizationId,
    logout,
    subDomain: currentSubDomain,
  } = useAuth();
  const snackbar = useSnackbarDispatch();
  const {
    meetingRequests,
    fetchRequests,
    loadingRequests,
    setMeetingRequests,
    pageCount,
    declinedMeetingRequests,
    setDeclinedMeetingRequests,
    fetchDeclinedRequests,
  } = useRequestsFetch();
  const modal = useModalState();

  const [pendingRequests, setPendingRequests] = useState(0);
  const [deletedChat, setDeletedChat] = useState([]);

  const pubnub = useMemo(() => {
    if (user) {
      return new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        uuid: user.chatId,
      });
    }
    return null;
  }, [user]);

  const {
    fetchSubscribedChats,
    chats,
    activeChat,
    messages,
    dispatchChats,
    handleSetActiveChat,
    loadingMessages,
    loadingChats,
    unreadChats,
    setUnreadChats,
    updateNewChatMessage,
    dispatchMessages,
    manageUnreadMetadata,
    activeChatData,
  } = useChat(pubnub, user, organizationId, chatLocatorPath, snackbar);

  const notificationAudio = useMemo(() => {
    const chat = new Audio(chatSound);
    const notification = new Audio(notificationSound);

    chat.load();
    notification.load();
    chat.muted = true;
    notification.muted = true;

    return {
      chat,
      notification,
    };
  }, []);

  const notify = (title, body) => {
    const options = {
      body,
      dir: 'ltr',
    };

    // eslint-disable-next-line no-new
    new Notification(title, options);
  };

  const redirectToNewDomain = async (subDomain, showModal) => {
    if (showModal) {
      await new Promise((r) => setTimeout(r, 2000));
      modal.show();
      await new Promise((r) => setTimeout(r, 5000));
    }
    const { href } = window.location;
    const url = href.replace(currentSubDomain, subDomain);
    window.location.href = url;
  };

  const getRequestsCount = useCallback(async () => {
    if (user && organizationId) {
      let newCount = 0;
      const params = {
        organization: organizationId,
        status: 'PENDING',
      };

      const isRole = (role) => user.groups.some((g) => g.name === role);

      if (isRole(groups.SCHEDULER) || isRole(groups.ADMINISTRATOR)) {
        const { count } = await RequestsResource.getAll(params);
        newCount = count;
      } else if (isRole(groups.SESSION_HOST)) {
        const { count } = await RequestsResource.getHostMeetingRequests(params);
        newCount = count;
      }

      setPendingRequests(newCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, user]);

  useEffect(() => {
    fetchSubscribedChats();
  }, [fetchSubscribedChats]);

  useEffect(() => {
    if (pubnub) {
      pubnub.removeAllListeners();
      pubnub.addListener({
        message: (messageEvent) => {
          if (messageEvent.channel === `notificationChannel${organizationId}`) {
            if (messageEvent.message.resource === 'meetingRequests') {
              getRequestsCount();
            } else if (
              messageEvent.message.resource === 'logout' &&
              user.id === messageEvent.message.user
            ) {
              logout();
              return;
            } else if (messageEvent.message.resource === 'newChatArrival') {
              if (
                window.location.pathname.indexOf(chatLocatorPath) === -1 &&
                messageEvent.message.members.some((m) => m === user.id)
              ) {
                notificationAudio.chat.muted = false;
                notificationAudio.chat.play();

                setUnreadChats(unreadChats + 1);
              } else if (
                messageEvent.message.members.some((m) => m === user.id)
              ) {
                fetchSubscribedChats(null, true);
              }
            } else if (messageEvent.message.resource === 'changedSubDomain') {
              redirectToNewDomain(
                messageEvent.message.subDomain,
                user.id !== messageEvent.message.user,
              );
            }

            if (
              Notification.permission === 'granted' &&
              messageEvent.message.body
            ) {
              notificationAudio.notification.muted = false;
              notificationAudio.notification.play();
              notify(messageEvent.message.title, messageEvent.message.body);
            }

            if (messageEvent.message.action) {
              fetchRequests();
              if (messageEvent.message.declined) {
                fetchDeclinedRequests();
              }
            }
          } else if (window.location.pathname.indexOf(chatLocatorPath) === -1) {
            updateNewChatMessage(messageEvent.channel, true);

            notificationAudio.chat.muted = false;
            notificationAudio.chat.play();
          } else if (messageEvent.message.resource === 'deleteChat') {
            setDeletedChat((prevState) => [...prevState, messageEvent.channel]);
          } else if (
            messageEvent.channel === activeChat &&
            messageEvent.message?.text
          ) {
            const timestamp = momentTimezone(
              moment.unix(messageEvent.timetoken / 10000000),
            )
              .tz(user.timezone)
              .format('dddd, MMMM Do, hh:mm A');
            // const mm =
            //   messageEvent.message && messageEvent.message.text
            //     ? messageEvent.message.text
            //     : messageEvent.message;
            const m = {
              text: messageEvent.message?.text,
              type: 'self',
              timestamp,
              sender: messageEvent.message.name,
            };
            if (messageEvent.publisher !== user.chatId) {
              m.type = 'incoming';
            }

            dispatchMessages({ type: 'update', data: m });
            manageUnreadMetadata(messageEvent);
          } else {
            updateNewChatMessage(messageEvent.channel, true);
          }
        },

        file: (fileMessageEvent) => {
          if (window.location.pathname.indexOf(chatLocatorPath) === -1) {
            updateNewChatMessage(fileMessageEvent.channel, true);

            notificationAudio.chat.muted = false;
            notificationAudio.chat.play();
          } else if (fileMessageEvent.channel === activeChat) {
            const timestamp = momentTimezone(
              moment.unix(fileMessageEvent.timetoken / 10000000),
            )
              .tz(user.timezone)
              .format('dddd, MMMM Do, hh:mm A');

            const mm = fileMessageEvent.file.name;
            const m = {
              text: mm,
              type: 'self',
              file: fileMessageEvent.file.id,
              timestamp,
              sender: fileMessageEvent.message.name,
            };
            if (fileMessageEvent.publisher !== user.chatId) {
              m.type = 'incoming';
            }
            dispatchMessages({ type: 'update', data: m });
            manageUnreadMetadata(fileMessageEvent);
          } else {
            updateNewChatMessage(fileMessageEvent.channel, true);
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    pubnub,
    organizationId,
    notificationAudio,
    chats,
    messages,
    activeChat,
    getRequestsCount,
  ]);

  useEffect(() => {
    getRequestsCount();
  }, [getRequestsCount]);

  useEffect(() => {
    window.addEventListener('offline', () => {
      snackbar.open({
        message:
          'It seems that you have lost connection to the internet. Please check you internet connection and reload this page.',
        status: 'error',
        autoHideDuration: 1000 * 60 * 60 * 24,
      });
    });
  }, [snackbar]);

  const context = {
    pubnub,
    fetchSubscribedChats,
    chats,
    activeChat,
    activeChatData,
    messages,
    dispatchChats,
    handleSetActiveChat,
    loadingMessages,
    loadingChats,
    unreadChats,
    pendingRequests,
    setPendingRequests,
    notify,
    fetchRequests,
    loadingRequests,
    meetingRequests,
    setMeetingRequests,
    pageCount,
    fetchDeclinedRequests,
    declinedMeetingRequests,
    setDeclinedMeetingRequests,
    deletedChat,
    modal,
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationsProvider.propTypes = propTypes;

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

export default NotificationsProvider;
