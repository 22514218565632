import Resource from './base';

const baseUrl = '/api/meeting_requests';
const baseUrlParticipants = '/api/meeting_requests_participant';
const baseUrlHosts = '/api/meeting_requests_host';

const actions = (client) => ({
  createMeetingRequest(data) {
    return client.post(`${baseUrlParticipants}`, data);
  },

  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  getParticipantMeetingRequests(params) {
    return client.get(`${baseUrlParticipants}`, { params });
  },

  getHostMeetingRequests(params) {
    return client.get(`${baseUrlHosts}`, { params });
  },

  availableProviders(id, params) {
    return client.get(`${baseUrl}/${id}/providers`, { params });
  },

  confirmRequest(id, params) {
    return client.put(`${baseUrlParticipants}/${id}`, { ...params });
  },

  cancelRequest(id, organization) {
    return client.delete(
      `${baseUrlParticipants}/${id}?organization=${organization}`,
    );
  },

  acceptAsHost(id, data) {
    return client.put(`${baseUrlHosts}/${id}`, data);
  },

  declineAsHost(id, data) {
    return client.put(`${baseUrlHosts}/${id}`, data);
  },

  removeRequestMessage(id, data) {
    return client.put(
      `${baseUrlParticipants}/${id}/remove_request_message`,
      data,
    );
  },
});

export default Resource(baseUrl, actions);
