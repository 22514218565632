import React, { useCallback, useEffect, useState } from 'react';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Spinner from '@bit/medicalwebexperts.mwe-ui.spinner';
import { node } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { groups as GROUPS } from '../../constants/groups';
import useRequestsFetch from '../../hooks/requestsFetch';
import { useBranding } from '../../contexts/BrandingContext';
import filterByPermission from '../../utils/filterByPermission';

const propTypes = {
  children: node.isRequired,
};

const PrivateRoute = ({ children, ...props }) => {
  const { isLoggedIn, verifyAuth, user, accessCode, chatCode } = useAuth();
  const { paymentConnection } = useBranding();
  const {
    meetingRequests,
    fetchRequests,
    loadingRequests,
  } = useRequestsFetch();

  const [isUserLogged, setIsUserLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { ADMINISTRATOR, SESSION_PARTICIPANT, BILLING_MANAGER } = GROUPS;

  const verifyAuthentication = useCallback(async () => {
    try {
      const loggedIn = isLoggedIn();
      if (loggedIn) {
        setIsUserLogged(loggedIn);
      } else {
        const verificationResult = await verifyAuth();
        setIsUserLogged(verificationResult);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoggedIn, verifyAuth]);

  useEffect(() => {
    verifyAuthentication();
  }, [verifyAuthentication]);

  useEffect(() => {
    if (user && user.fromBooking) fetchRequests();
  }, [user, fetchRequests]);

  const redirectUser = (location) => {
    const { pathname } = location;
    if (user) {
      if (pathname === '/' || pathname === '/account/login') return '/sessions';
      if (chatCode) return '/chat';
      const bookingLogin = user.fromBooking && meetingRequests.length === 0;
      const participantFirstLogin =
        user.groups.some((g) => g.name === SESSION_PARTICIPANT) &&
        user.firstLogin &&
        !user.isTemporary;
      if (bookingLogin || participantFirstLogin) return '/requests';
      const hasPermission = filterByPermission(user).some((p) =>
        pathname.includes(p.to),
      );
      if (!hasPermission) return '/not-found';
      const groupNames = user.groups.map((group) => group.name);
      if (
        paymentConnection &&
        [ADMINISTRATOR, BILLING_MANAGER].some((el) => groupNames.includes(el))
      )
        return '/payments';
      return false;
    }
    return false;
  };

  const redirectLogout = () => {
    if (accessCode === 'logout') return '/join';
    if (chatCode === 'logout') return '/chatCode';
    return '/account/login';
  };

  if (isLoading || loadingRequests) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        isUserLogged ? (
          <>
            {redirectUser(location) && (
              <Redirect
                to={{
                  pathname: redirectUser(location),
                }}
              />
            )}
            {children}
          </>
        ) : (
          <Redirect
            to={{
              pathname: redirectLogout(),
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
