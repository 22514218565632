import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ProgressBar from '@bit/medicalwebexperts.mwe-ui.progress-bar';
import LoggedInLayout from '../Layout/LoggedInLayout';
import LoggedOutLayout from '../Layout/LoggedOutLayout';
import ApiDocumentLayout from '../Layout/ApiDocumentLayout';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import PrivateRoute from '../Routing/PrivateRoute';

import SessionsProvider from '../../contexts/SessionsContext';
import ClientFilingProvider from '../../contexts/ClientFilingContext';
import GroupsProvider from '../../contexts/GroupsContext';
import InjectAxiosInterceptors from '../../services/InjectAxiosInterceptor';

const AccountUsers = lazy(() =>
  import('../../pages/AccountUsers/AccountUsers'),
);
const Chat = lazy(() => import('../../pages/Chat/Chat'));
const SessionNotes = lazy(() =>
  import('../../pages/SessionNotes/SessionNotes'),
);
const SessionNotesTemplates = lazy(() =>
  import('../../pages/SessionNotes/SessionNotesTemplate'),
);
const SessionNotesView = lazy(() =>
  import('../../pages/SessionNotes/SessionNotesView'),
);
const Documents = lazy(() => import('../../pages/Documents/Documents'));
const DocumentsView = lazy(() => import('../../pages/Documents/DocumentsView'));
const Templates = lazy(() => import('../../pages/Documents/Templates'));
const TemplatePreview = lazy(() =>
  import('../../pages/Documents/TemplatePreview'),
);
const DocumentPreview = lazy(() =>
  import('../../pages/Documents/DocumentPreview'),
);
const Packets = lazy(() => import('../../pages/Documents/Packets'));
const DocumentPackets = lazy(() =>
  import('../../pages/Documents/DocumentPackets'),
);
const Calendar = lazy(() => import('../../pages/Calendar/Calendar'));
const Contacts = lazy(() => import('../../pages/Contacts/Contacts'));
const Reports = lazy(() => import('../../pages/Reports/Reports'));
const SessionUsage = lazy(() => import('../../pages/Reports/SessionUsage'));
const Geolocation = lazy(() => import('../../pages/Reports/Geolocation'));
const Queues = lazy(() => import('../../pages/Reports/Queues'));
const Logins = lazy(() => import('../../pages/Reports/Logins'));
const Support = lazy(() => import('../../pages/Support/Support'));
const Profile = lazy(() => import('../../pages/Profile/Profile'));
const Providers = lazy(() => import('../../pages/Providers/Providers'));

const BusinessAssociateAgreement = lazy(() =>
  import('../../pages/Accounts/BusinessAssociateAgreement'),
);
const Branding = lazy(() => import('../../pages/Accounts/Branding'));
const Settings = lazy(() =>
  import('../../pages/AccountSettings/AccountSettings'),
);
const Accounts = lazy(() => import('../../pages/Accounts/Accounts'));
const SessionsPage = lazy(() => import('../../pages/Sessions/SessionsPage'));
const SessionDetail = lazy(() => import('../../pages/Sessions/SessionDetail'));
const AccountServices = lazy(() =>
  import('../../pages/AccountServices/AccountServices'),
);
const ServiceGroups = lazy(() =>
  import('../../pages/ServiceGroups/ServiceGroups'),
);
const EditServiceGroup = lazy(() =>
  import('../../pages/ServiceGroups/EditServiceGroup'),
);

const LoginPage = lazy(() => import('../../pages/Account/Login'));
const ForgotPasswordPage = lazy(() =>
  import('../../pages/Account/ForgotPassword'),
);
const ResetPasswordPage = lazy(() =>
  import('../../pages/Account/ResetPassword'),
);
const VerifyAccountPage = lazy(() =>
  import('../../pages/Account/VerifyAccount'),
);
const NoMatchPage = lazy(() => import('../../pages/404'));

const Join = lazy(() => import('../../pages/Join/Join'));
const ChatCode = lazy(() => import('../../pages/ChatCode/ChatCode'));
const Device = lazy(() => import('../../pages/Device/Device'));
const Book = lazy(() => import('../../pages/Book/Book'));
const Services = lazy(() => import('../../pages/Book/Services'));
const Schedule = lazy(() => import('../../pages/Book/Schedule'));
const Register = lazy(() => import('../../pages/Book/Register'));
const SessionJoin = lazy(() => import('../../pages/Join/SessionJoin'));

const ClientFiling = lazy(() =>
  import('../../pages/ClientFiling/ClientFiling'),
);

const Transactions = lazy(() => import('../../pages/Reports/Transactions'));
const Payments = lazy(() => import('../../pages/Payments/Payments'));
const Billing = lazy(() => import('../../pages/Billing/Billing'));
const ApiDocumentation = lazy(() =>
  import('../../pages/Documentation/ApiDocumentation'),
);

const Loader = (props) => (
  <Suspense
    {...props}
    fallback={
      <ProgressBar
        classes={{
          root: { position: 'absolute', top: 0, left: 0 },
        }}
        size="sm"
      />
    }
  />
);

const App = () => (
  <Router>
    <InjectAxiosInterceptors />
    <ErrorBoundary>
      <Switch>
        <Route path="/account">
          <LoggedOutLayout>
            <Loader>
              <Switch>
                <Route path="/account/login" exact>
                  <LoginPage />
                </Route>
                <Route path="/account/forgot-password" exact>
                  <ForgotPasswordPage />
                </Route>
                <Route path="/account/reset-password/:token" exact>
                  <ResetPasswordPage />
                </Route>
                <Route path="/account/verification/:token" exact>
                  <VerifyAccountPage />
                </Route>
                <Redirect to="/account/login" />
              </Switch>
            </Loader>
          </LoggedOutLayout>
        </Route>

        <Route path="/book">
          <LoggedOutLayout>
            <Loader>
              <Switch>
                <Route path="/book" exact>
                  <Register />
                </Route>
              </Switch>
            </Loader>
          </LoggedOutLayout>
        </Route>

        <Route path="/session">
          <Loader>
            <Route path="/session/join/:invitationId" exact>
              <SessionJoin />
            </Route>
          </Loader>
        </Route>

        <Route path="/join">
          <LoggedOutLayout>
            <Loader>
              <Route path="/join/:accessCode?/:action?" exact>
                <Join />
              </Route>
            </Loader>
          </LoggedOutLayout>
        </Route>

        <Route path="/chatCode">
          <LoggedOutLayout>
            <Loader>
              <Route path="/chatCode/:chatCode?/" exact>
                <ChatCode />
              </Route>
            </Loader>
          </LoggedOutLayout>
        </Route>

        <Route path="/device">
          <LoggedOutLayout>
            <Loader>
              <Route path="/device" exact>
                <Device />
              </Route>
            </Loader>
          </LoggedOutLayout>
        </Route>

        <Route path="/api">
          <Loader>
            <ApiDocumentLayout>
              <Route path="/api/documentation" exact>
                <ApiDocumentation />
              </Route>
            </ApiDocumentLayout>
          </Loader>
        </Route>

        <PrivateRoute path="/">
          <LoggedInLayout>
            <Loader>
              <Switch>
                <Route path="/users" exact>
                  <GroupsProvider>
                    <AccountUsers />
                  </GroupsProvider>
                </Route>

                <Route path="/accounts">
                  <Switch>
                    <Route path="/accounts/branding/:id?">
                      <Branding />
                    </Route>
                    <Route path="/accounts/baa">
                      <BusinessAssociateAgreement />
                    </Route>
                    <Route path="/accounts" exact>
                      <Accounts />
                    </Route>
                  </Switch>
                </Route>
                <Route path="/profile" exact>
                  <Profile />
                </Route>
                <Route path="/providers" exact>
                  <Providers />
                </Route>

                <Route path="/settings" exact>
                  <Settings />
                </Route>

                <Route path="/clientFiling/:id/:isContact" exact>
                  <ClientFilingProvider>
                    <ClientFiling />
                  </ClientFilingProvider>
                </Route>

                <Route path="/requests">
                  <Switch>
                    <Route path="/requests" exact>
                      <Book />
                    </Route>
                    <Route path="/requests/services" exact>
                      <Services />
                    </Route>
                    <Route path="/requests/schedule" exact>
                      <Schedule isRequest />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/chat">
                  <Switch>
                    <Route path="/chat/:id?" exact>
                      <Chat />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/documents">
                  <SessionsProvider>
                    <Switch>
                      <Route path="/documents/view/:id" exact>
                        <DocumentsView />
                      </Route>
                      <Route path="/documents/templates" exact>
                        <Templates />
                      </Route>
                      <Route path="/documents/preview" exact>
                        <DocumentPreview />
                      </Route>
                      <Route path="/documents/templates/preview" exact>
                        <TemplatePreview />
                      </Route>
                      <Route path="/documents/packets/" exact>
                        <Packets />
                      </Route>
                      <Route
                        path="/documents/packets/documents_packets/:id"
                        exact
                      >
                        <DocumentPackets />
                      </Route>
                      <Route path="/documents/:id?/:mod?">
                        <Documents />
                      </Route>
                    </Switch>
                  </SessionsProvider>
                </Route>

                <Route path="/contacts/:owner?" exact>
                  <Contacts />
                </Route>

                <Route path="/reports">
                  <Switch>
                    <Route path="/reports" exact>
                      <Reports />
                    </Route>
                    <Route path="/reports/sessionUsage" exact>
                      <SessionUsage />
                    </Route>
                    <Route path="/reports/geolocation" exact>
                      <Geolocation />
                    </Route>
                    <Route path="/reports/queues" exact>
                      <Queues />
                    </Route>
                    <Route path="/reports/logins" exact>
                      <Logins />
                    </Route>
                    <Route path="/reports/payments" exact>
                      <Transactions />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/sessions">
                  <SessionsProvider>
                    <Switch>
                      <Route path="/sessions/sessionDetail/:id?/:host?" exact>
                        <SessionDetail />
                      </Route>
                      <Route path="/sessions/calendar/:id?" exact>
                        <Calendar />
                      </Route>
                      <Route path="/sessions/:host?/:contact?/:user?" exact>
                        <SessionsPage />
                      </Route>
                    </Switch>
                  </SessionsProvider>
                </Route>

                <Route path="/SessionNotesTemplates">
                  <Switch>
                    <Route path="/SessionNotesTemplates/:owner?" exact>
                      <SessionNotesTemplates />
                    </Route>
                    <Route
                      path="/SessionNotesTemplates/sessionNotesView/:owner/:id/:mod/:title"
                      exact
                    >
                      <SessionNotesView />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/sessionNotes">
                  <Switch>
                    <Route path="/sessionNotes/:owner?" exact>
                      <SessionNotes />
                    </Route>
                    <Route
                      path="/sessionNotes/sessionNotesView/:owner/:id/:mod/:title"
                      exact
                    >
                      <SessionNotesView />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/accountServices" exact>
                  <AccountServices />
                </Route>

                <Route path="/payments">
                  <Payments />
                </Route>

                <Route path="/serviceGroups">
                  <Switch>
                    <Route path="/serviceGroups" exact>
                      <ServiceGroups />
                    </Route>
                    <Route path="/serviceGroups/edit/:id" exact>
                      <EditServiceGroup />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/billing">
                  <Billing />
                </Route>

                <Route path="/support" exact>
                  <Support />
                </Route>

                <Route path="*">
                  <NoMatchPage />
                </Route>
              </Switch>
            </Loader>
          </LoggedInLayout>
        </PrivateRoute>
      </Switch>
    </ErrorBoundary>
  </Router>
);

export default App;
