import Resource from './base';

const baseUrl = '/api';

const actions = (client) => ({
  async login(credentials) {
    const { access, refresh } = await client.post(
      `${baseUrl}/token`,
      credentials,
    );

    if (access && refresh) {
      client.$setToken(access, refresh);
      return access;
    }
    return null;
  },

  async chatCodeLogin(chatCode) {
    const { refresh, access } = await client.post(
      `${baseUrl}/chat/token`,
      chatCode,
    );

    if (refresh && access) {
      client.$setToken(access, refresh);
      return access;
    }
    return null;
  },

  logout(credentials) {
    return client.post(`${baseUrl}/token/logout`, credentials);
  },

  validateToken(token) {
    return client.post(`${baseUrl}/password_reset/validate_token/`, token);
  },

  resetPassword(data, confirm) {
    return client.post(
      `${baseUrl}/password_reset/${confirm ? 'confirm/' : ''}`,
      data,
    );
  },

  changePassword(data) {
    return client.put(`${baseUrl}/change_password`, data);
  },

  me() {
    return client.get(`${baseUrl}/users/me`);
  },

  async join(accessCode) {
    const {
      access,
      refresh,
    } = await client.post(`${baseUrl}/participant/token`, { accessCode });
    if (access && refresh) {
      client.$setToken(access, refresh);
      return access;
    }
    return null;
  },

  async device(deviceId) {
    const { access, refresh } = await client.post(`${baseUrl}/device/token`, {
      deviceId,
    });
    if (access && refresh) {
      client.$setToken(access, refresh);
      return access;
    }
    return null;
  },
});

export default Resource(baseUrl, actions);
