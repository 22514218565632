import Resource from './base';

const baseUrl = '/api/export';

const actions = (client) => ({
  users(params) {
    return client.get(`${baseUrl}/users`, { params });
  },

  organizations(params) {
    return client.get(`${baseUrl}/organizations`, { params });
  },
});

export default Resource(baseUrl, actions);
