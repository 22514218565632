import { useState, useCallback } from 'react';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import { useAuth } from '../contexts/AuthContext';
import { groups as GROUPS } from '../constants/groups';
import { RequestsResource } from '../services';
import snackbarTimeout from '../constants/snackbarTimer';

const useRequestsFetch = () => {
  const [meetingRequests, setMeetingRequests] = useState([]);
  const [declinedMeetingRequests, setDeclinedMeetingRequests] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const snackbar = useSnackbarDispatch();
  const [loadingRequests, setLoadingRequests] = useState(false);
  const { organizationId, user } = useAuth();
  const grp = user?.groups.map((g) => g.name);

  const isProvider = grp?.includes(GROUPS.SESSION_HOST);
  const isScheduler =
    grp?.includes(GROUPS.SCHEDULER) || grp?.includes(GROUPS.ADMINISTRATOR);
  const isParticipant = grp?.includes(GROUPS.SESSION_PARTICIPANT);

  const fetchRequests = useCallback(
    async (host) => {
      try {
        setLoadingRequests(true);
        if (isProvider || host) {
          const {
            results,
            count,
          } = await RequestsResource.getHostMeetingRequests({
            organization: organizationId,
            status: 'PENDING',
            host: host || undefined,
          });
          setPageCount(Math.ceil(count / 10));
          const sortedResults = results.sort((a) => {
            if (a.requestType === 'N') return -1;
            return 1;
          });
          setMeetingRequests(sortedResults);
        } else if (isScheduler) {
          const { results, count } = await RequestsResource.getAll({
            organization: organizationId,
            status: 'PENDING',
          });
          setPageCount(Math.ceil(count / 10));
          const sortedResults = results.sort((a) => {
            if (a.requestType === 'N') return -1;
            return 1;
          });
          setMeetingRequests(sortedResults);
        } else {
          const {
            results,
          } = await RequestsResource.getParticipantMeetingRequests({
            organization: organizationId,
          });
          setMeetingRequests(results);
        }
      } catch (error) {
        snackbar.open({
          message: `Sorry, there was an error fetching requests. Please, try later.`,
          status: 'error',
          autoHideDuration: snackbarTimeout,
        });
      } finally {
        setLoadingRequests(false);
      }
    },
    [isProvider, isScheduler, organizationId, snackbar],
  );

  const fetchDeclinedRequests = useCallback(async () => {
    try {
      if (isParticipant) {
        const {
          results,
        } = await RequestsResource.getParticipantMeetingRequests({
          organization: organizationId,
          status: 'DECLINED',
          show_message: true,
        });
        setDeclinedMeetingRequests(results);
      }
    } catch (error) {
      snackbar.open({
        message: `Sorry, there was an error fetching requests. Please, try later.`,
        status: 'error',
        autoHideDuration: snackbarTimeout,
      });
    }
  }, [isParticipant, organizationId, snackbar]);

  return {
    fetchRequests,
    loadingRequests,
    meetingRequests,
    setMeetingRequests,
    pageCount,
    fetchDeclinedRequests,
    declinedMeetingRequests,
    setDeclinedMeetingRequests,
  };
};

export default useRequestsFetch;
