import Resource from './base';

const baseUrl = '/api/chat_rooms';

const actions = (client) => ({
  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  subscribed(organization) {
    return client.get(`${baseUrl}/subscribed`, {
      params: {
        organization,
      },
    });
  },

  removeChat(id, organization) {
    return client.delete(`${baseUrl}/${id}`, {
      params: {
        organization,
      },
    });
  },

  getChatCode(organization, user, chat) {
    return client.get(`${baseUrl}/${chat}/code`, {
      params: {
        organization,
        user,
      },
    });
  },

  getChatRoomByRoomUuid(organization, uuid) {
    return client.get(`${baseUrl}`, {
      params: {
        organization,
        search: uuid,
      },
    });
  },

  chatWithHost(organization, meeting) {
    return client.post(`${baseUrl}/chat_host`, {
      organization,
      meeting,
    });
  },

  notifyAbsentMembers(organization, users, chatRoom) {
    return client.post(`${baseUrl}/notification`, {
      organization,
      users,
      chatRoom,
    });
  },
});

export default Resource(baseUrl, actions);
