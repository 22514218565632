import React from 'react';
import { string } from 'prop-types';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import defaultLogo from '../../assets/images/LTI-logo.png';

const propTypes = {
  logo: string,
  width: string,
};

const defaultProps = {
  logo: null,
  width: '35%',
};

const generateLogoPath = (logo) =>
  `${process.env.REACT_APP_API_BASE_URL}${logo}`;

const Logo = ({ logo, width, ...props }) => {
  return (
    <Flex alignItems="center" justifyContent="center" {...props}>
      <Image
        src={logo ? generateLogoPath(logo) : defaultLogo}
        alt="logo"
        width={width}
        height="100%"
      />
    </Flex>
  );
};

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;
export default Logo;
