import React, { useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';

import Modal, { useModalState } from '@bit/medicalwebexperts.mwe-ui.modal';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { useDown } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import zoomLogo from '../../assets/images/zoom-logo.svg';
import { useModal } from '../../contexts/ModalContext';

const propTypes = {};
const zoomLink = 'https://zoom.us/download';
const dismissedLoc = 'dismissedZoomModal';

const StyledText = styled(Typography)(() =>
  css({
    color: '#707070',
    fontSize: 16,
  }),
);

const StyledButton = styled(Button)(() =>
  css({
    bg: '#215371',
  }),
);

const location = window.location.hostname.split('.');
location.shift();
const domain = location.join('.');

const ZoomModal = () => {
  const isMobile = useDown('lg');
  const modalState = useModalState();
  const { desktopModal, setDesktopModal } = useModal();

  const evaluateDismissedModal = useCallback(() => {
    const dismissedModal = Cookies.get(dismissedLoc, { domain });
    if (!dismissedModal) {
      modalState.show();
    }
  }, [modalState]);

  useEffect(() => {
    if (desktopModal) evaluateDismissedModal();
  }, [desktopModal, evaluateDismissedModal]);

  const handleClose = (dismiss) => {
    if (dismiss) {
      Cookies.set(dismissedLoc, 'true', { domain, expires: 3650 });
    }
    modalState.hide();
    setDesktopModal(null);
  };

  const handleDownloadZoom = () => {
    window.open(zoomLink);
    modalState.hide();
    setDesktopModal(null);
  };

  return (
    <Modal
      state={modalState}
      ariaLabel="Zoom Modal"
      withBackdrop
      tabIndex={0}
      top={isMobile ? 0 : '8vh'}
      width={isMobile ? '100%' : '500px'}
      height={isMobile ? '100%' : 'auto'}
      maxHeight={!isMobile ? '90vh' : undefined}
      overflow="auto"
    >
      <Flex mt={5} justifyContent="center">
        <Image src={zoomLogo} alt="Zoom login" width="250px" height="100px" />
      </Flex>
      <Typography color="#215371" mt={6} variant="h2" textAlign="center">
        Download and Install Zoom
      </Typography>
      <Modal.Body px={4}>
        <StyledText variant="body2" textAlign="center" paragraph>
          In order to videoconference, you&apos;ll need to download and install
          Zoom.
        </StyledText>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent="flex-end"
          flexDirection={isMobile ? 'column' : null}
        >
          <Button
            variant="text"
            onClick={() => handleClose(false)}
            ml={[0, 2, 4, null]}
            mt={[2, 2, 0, null]}
          >
            Not Now
          </Button>
          <Flex flex={1} />
          <StyledButton
            onClick={() => handleClose(true)}
            ml={[0, 2, 4, null]}
            mt={[2, 2, 0, null]}
          >
            Don&apos;t ask me again
          </StyledButton>
          <Button
            variantColor="secondary"
            onClick={handleDownloadZoom}
            ml={[0, 2, 4, null]}
            mt={[2, 2, 0, null]}
          >
            Download Zoom
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

ZoomModal.propTypes = propTypes;
export default ZoomModal;
