import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { node } from 'prop-types';
import PubNub from 'pubnub';
import { useHistory } from 'react-router-dom';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import { useModalState } from '@bit/medicalwebexperts.mwe-ui.modal';
import { ChatResource, DocumentsResource } from '../services';
import { useAuth } from './AuthContext';

export const SessionsContext = createContext({
  sessions: [],
  setSessions: () => null,
  documents: [],
});

export const useSessions = () => useContext(SessionsContext);

const propTypes = {
  children: node.isRequired,
};

const SessionsProvider = ({ children }) => {
  const snackbar = useSnackbarDispatch();
  const { organizationId, user } = useAuth();
  const history = useHistory();
  const membersSetupModal = useModalState();
  const [sessions, setSessions] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [services, setServices] = useState([]);
  const [participantsSetupId, setParticipantsSetupId] = useState();
  const [participantSetupOptions, setParticipantsSetupOptions] = useState([]);
  const [participantsSetup, setParticipantsSetup] = useState([]);

  const getDocuments = useCallback(async () => {
    try {
      if (organizationId) {
        const { results } = await DocumentsResource.listPacketsSession({
          organization: organizationId,
          no_page: '',
        });

        if (results && results.length) {
          const filteredResults = results.filter(
            (el) => !!el.documentPacket.length,
          );
          setDocuments(
            filteredResults.map((elem) => ({
              label: elem.name,
              value: elem.id,
              documentPacket: elem.documentPacket,
            })),
          );
        }
      }
    } catch (error) {
      snackbar.open({
        message: `Sorry, there was an error fetching Documents: ${error}. Please, try later.`,
        status: 'error',
      });
    }
  }, [organizationId, snackbar]);

  useEffect(() => {
    if (!documents.length) getDocuments();
  }, [documents, getDocuments]);

  const pubnub = useMemo(() => {
    if (user) {
      return new PubNub({
        publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
        uuid: user.chatId,
      });
    }
    return null;
  }, [user]);

  const handleMembersSetupInit = useCallback(
    async (chatuuid, id) => {
      const { results } = await ChatResource.getChatRoomByRoomUuid(
        organizationId,
        chatuuid,
      );

      if (results.length > 0) {
        history.push(`/chat/${id}`);
      } else {
        const idx = sessions.findIndex((s) => s.id === id);
        const participantsToSetup = sessions[idx].participants.map((p) => ({
          value: p.user,
          label: p.userName,
        }));

        if (participantsToSetup.length === 1) {
          await ChatResource.save({
            organization: organizationId,
            meeting: id,
            participants: [participantsToSetup[0].value, user.id],
          });

          await pubnub.publish({
            channel: `notificationChannel${organizationId}`,
            message: {
              resource: 'newChatArrival',
              members: [participantsToSetup[0].value],
            },
          });

          history.push(`/chat/${id}`);
        } else {
          setParticipantsSetupId(id);
          setParticipantsSetup([...participantsToSetup]);
          setParticipantsSetupOptions([...participantsToSetup]);

          membersSetupModal.show();
        }
      }
    },
    [sessions, history, organizationId, user, pubnub, membersSetupModal],
  );

  const SessionStartedOnDevice = (channel, sessionId, userParticipantId) => {
    pubnub.publish({
      channel,
      message: {
        sessionStartedOnDevice: true,
        sessionId,
        userParticipantId,
      },
    });
  };
  const startSessionDeviceRealTime = (
    channel,
    sessionId,
    userParticipantId,
  ) => {
    pubnub.publish({
      channel,
      message: {
        startSessionDeviceRealTime: true,
        sessionId,
        userParticipantId,
      },
    });
  };
  const RSVPRealTimeUpdate = (channel, userId, sessionId, RSVPUpdate) => {
    pubnub.publish({
      channel,
      message: {
        RSVPUpdate,
        userId,
        sessionId,
      },
    });
  };
  const updateSession = (channel, userId, sessionId) => {
    pubnub.publish({
      channel,
      message: {
        isUpdateSession: true,
        userId,
        sessionId,
      },
    });
  };
  const paidSessionUpdate = (channel, userId, sessionId) => {
    pubnub.publish({
      channel,
      message: {
        isPaidSessionUpdated: true,
        userId,
        sessionId,
      },
    });
  };
  const enterMeetingRealTimeUpdate = (channel, userId, sessionId) => {
    pubnub.publish({
      channel,
      message: {
        enterMeetingRealTimeUpdate: true,
        userId,
        sessionId,
      },
    });
  };
  const submissionStatusUpdate = (channel, submissionId, sessionId) => {
    pubnub.publish({
      channel,
      message: {
        submissionStatusUpdate: true,
        submissionId,
        sessionId,
      },
    });
  };
  const context = {
    sessions,
    setSessions,
    documents,
    participantsSetupId,
    participantSetupOptions,
    startSessionDeviceRealTime,
    SessionStartedOnDevice,
    RSVPRealTimeUpdate,
    enterMeetingRealTimeUpdate,
    submissionStatusUpdate,
    paidSessionUpdate,
    pubnub,
    handleMembersSetupInit,
    participantsSetup,
    setParticipantsSetup,
    membersSetupModal,
    services,
    setServices,
    updateSession,
  };

  return (
    <SessionsContext.Provider value={context}>
      {children}
    </SessionsContext.Provider>
  );
};

SessionsProvider.propTypes = propTypes;

export default SessionsProvider;
