import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Avatar from '@bit/medicalwebexperts.mwe-ui.avatar';
import Dropdown, {
  useDropdownState,
} from '@bit/medicalwebexperts.mwe-ui.dropdown';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import { useAuth } from '../../../contexts/AuthContext';

const StyledDropdownItem = styled(Dropdown.Item)(({ selected }) =>
  css({
    opacity: selected ? 0.5 : undefined,
    py: 4,
    pl: 2,
    '&:hover': { bg: 'gray.200' },
  }),
);

const buttonClasses = {
  root: {
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '0px',
    border: 'none',
    outline: 'none',
    '&:hover': {
      bg: 'sidebarHighlightColor',
      color: 'sidebarHighlightTextColor',
    },
    '&.active': {
      bg: 'sidebarHighlightColor',
      color: 'sidebarHighlightTextColor',
      borderRightColor: 'sidebarHighlightColor',
    },
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightWidth: '6px',
    color: 'sidebarTextColor',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'md',
    fontWeight: 'medium',
    py: 6,
  },
};

const menuClasses = {
  root: {
    border: '1px solid',
    borderColor: 'gray.400',
    bg: 'sidebarDropdown',
  },
};

const generateLogoPath = (logo) =>
  `${process.env.REACT_APP_API_BASE_URL}${logo}`;

const AccountSwitcher = () => {
  const dropdown = useDropdownState();
  const { user, organizationId } = useAuth();
  const { organizations } = user;
  return (
    <Dropdown state={dropdown}>
      <Dropdown.Toggle
        render={(toggleProps) => (
          <Button
            variant="text"
            endIcon={<Icon name="arrowDown" size={6} />}
            {...toggleProps}
            classes={buttonClasses}
          >
            Switch Account
          </Button>
        )}
      />
      <Dropdown.Menu
        aria-label="Dropdown menu"
        width="100%"
        classes={menuClasses}
      >
        {organizations &&
          organizations.map((org) => {
            const { legalEntityName, logo } = org;
            const orgLogo = logo ? generateLogoPath(logo) : null;
            const rootURL = window.location.host.split('.').slice(1).join('.');
            return (
              <StyledDropdownItem
                key={legalEntityName}
                as="a"
                href={`https://${org.subDomain}.${rootURL}/`}
                selected={org.id === organizationId}
              >
                <Flex alignItems="center">
                  <Avatar
                    name={legalEntityName}
                    src={orgLogo}
                    size="xs"
                    classes={{
                      root: { height: 8, width: 8, fontSize: 'xs' },
                    }}
                    mr={2}
                    bg={orgLogo ? 'secondary.dark' : undefined}
                  />
                  <Flex flexDirection="column" flexWrap="wrap">
                    <Typography
                      variant="body2"
                      fontWeight="semibold"
                      classes={{
                        root: { textTransform: 'uppercase' },
                      }}
                    >
                      {legalEntityName}
                    </Typography>
                  </Flex>
                </Flex>
              </StyledDropdownItem>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AccountSwitcher;
