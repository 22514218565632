import React from 'react';
import { node, oneOfType, object } from 'prop-types';
import ThemeProvider from '@bit/medicalwebexperts.mwe-ui.theme-provider';
import Normalize from '@bit/medicalwebexperts.mwe-ui.normalize';

const propTypes = {
  children: node,
  theme: oneOfType([object]).isRequired,
};

const defaultProps = {
  children: null,
};

const Theme = ({ children, theme }) => (
  <ThemeProvider theme={theme}>
    <Normalize />
    {children}
  </ThemeProvider>
);

Theme.propTypes = propTypes;
Theme.defaultProps = defaultProps;
export default Theme;
