import Resource from './base';

const baseUrl = '/api/contacts';

const actions = (client) => ({
  get(id, params) {
    return client.get(`${baseUrl}/${id}`, { params });
  },

  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  delete(id, organization, owner) {
    return client.delete(`${baseUrl}/${id}`, {
      params: {
        organization,
        owner,
      },
    });
  },
});

export default Resource(baseUrl, actions);
