import React from 'react';
import { func } from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Avatar from '@bit/medicalwebexperts.mwe-ui.avatar';
import Dropdown, {
  useDropdownState,
} from '@bit/medicalwebexperts.mwe-ui.dropdown';
import Divider from '@bit/medicalwebexperts.mwe-ui.divider';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import { useDown } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import { useAuth } from '../../contexts/AuthContext';
import { StyledDropdownItem } from '../ListActions/ListActions';
import getUserRole from '../../utils/getUserRole';
import { useNotificationContext } from '../../contexts/NotificationsContext';
import snackbarTimeout from '../../constants/snackbarTimer';

const propTypes = {
  onClick: func,
};

const defaultProps = {
  onClick: undefined,
};

const buttonClasses = {
  root: {
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    outline: 'none',
    color: 'sidebarTextColor',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'md',
    fontWeight: 'medium',
    py: 2,
    px: 2,
    '&:hover': {
      bg: 'sidebarHighlightColor',
      color: 'sidebarHighlightTextColor',
    },
    '&.active': {
      bg: 'sidebarHighlightColor',
      color: 'sidebarHighlightTextColor',
    },
  },
};

const menuClasses = {
  root: {
    border: '1px solid',
    borderColor: 'gray.300',
  },
};

const StyledButton = styled(Button)(
  css({
    width: '100%',
    justifyContent: 'flex-start',
    textTransform: 'none',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'md',
    fontWeight: 'medium',
  }),
);

const ProfileDropdown = ({ onClick }) => {
  const dropdown = useDropdownState();
  const snackbar = useSnackbarDispatch();
  const { pubnub } = useNotificationContext();
  const { user, logout, organizationId } = useAuth();
  const isMobile = useDown('lg');
  const userRole = getUserRole(user);
  const isProvider =
    userRole === 'isSessionHost' ||
    userRole === 'isAdmin' ||
    userRole === 'isSuperuser';
  const handleClick = () => {
    if (onClick) onClick();
    dropdown.hide();
  };

  const handleLogout = async () => {
    try {
      await pubnub.publish({
        channel: `notificationChannel${organizationId}`,
        message: {
          resource: 'logout',
          user: user.id,
        },
      });
      await pubnub.unsubscribeAll();
      logout();
    } catch (error) {
      snackbar.open({
        message: `Sorry, there was an error signing out: ${error.message}. Please, try later.`,
        status: 'error',
        autoHideDuration: snackbarTimeout,
      });
    }
  };

  return (
    <Dropdown state={dropdown}>
      <Dropdown.Toggle
        render={(toggleProps) => (
          <Button
            variant="text"
            startIcon={
              <Avatar
                name={user.name}
                size="sm"
                mr={isMobile ? 0 : -3}
                ml={isMobile ? 3 : undefined}
                classes={{
                  root: {
                    height: 8,
                    width: 8,
                    fontSize: 'xs',
                    color: 'white',
                  },
                }}
                bg="sidebarHighlightColor"
              />
            }
            endIcon={<Icon name="arrowDown" size={6} />}
            {...toggleProps}
            classes={buttonClasses}
            borderRadius={isMobile ? 0 : 'md'}
          >
            {isMobile && 'Account'}
          </Button>
        )}
      />
      <Dropdown.Menu
        aria-label="Dropdown menu"
        bg="white"
        classes={menuClasses}
        width={isMobile ? '100%' : undefined}
      >
        <StyledDropdownItem>
          <StyledButton
            as={Link}
            to="/profile"
            variant="text"
            onClick={handleClick}
          >
            My Profile
          </StyledButton>
        </StyledDropdownItem>
        {isProvider && (
          <>
            <StyledDropdownItem>
              <StyledButton
                as={Link}
                to="/sessions/calendar"
                variant="text"
                onClick={handleClick}
              >
                My Schedule
              </StyledButton>
            </StyledDropdownItem>
            <StyledDropdownItem>
              <StyledButton
                as={Link}
                to="/sessionNotesTemplates"
                variant="text"
                onClick={handleClick}
              >
                Note Templates
              </StyledButton>
            </StyledDropdownItem>
          </>
        )}
        <Divider ml={0} mr={0} my={1} />
        <StyledDropdownItem>
          <StyledButton as={Button} variant="text" onClick={handleLogout}>
            Log Out
          </StyledButton>
        </StyledDropdownItem>
      </Dropdown.Menu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = propTypes;
ProfileDropdown.defaultProps = defaultProps;
export default ProfileDropdown;
