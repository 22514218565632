import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import { ContactsResource, UsersResource } from '../../services';
import '../AsyncMembersSelect/CustomAsyncStyles.css';
import snackbarTimeout from '../../constants/snackbarTimer';
import { useAuth } from '../../contexts/AuthContext';
import getUserRole from '../../utils/getUserRole';

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon name="search" size={6} />
      </components.DropdownIndicator>
    )
  );
};

const timeouts = [];
let usersPromise = null;

const AsyncContactsSelect = () => {
  const snackbar = useSnackbarDispatch();
  const { organizationId, user } = useAuth();
  const history = useHistory();

  const promiseOptions = (inputValue) => {
    timeouts.map((to) => clearTimeout(to));

    if (inputValue.length < 3) {
      return [];
    }

    if (
      getUserRole(user) === 'isAdmin' ||
      getUserRole(user) === 'isSuperuser'
    ) {
      usersPromise = new Promise((resolve) => {
        timeouts.push(
          setTimeout(() => {
            UsersResource.getAll({
              organization: organizationId,
              ...(inputValue ? { search: inputValue } : {}),
              limit: 100,
            })
              .then((response) => {
                let data = response.results;
                data = data.map((u) => ({
                  label: u.name,
                  value: u.id,
                  isUser: true,
                }));
                resolve(data);
              })
              .catch((error) => {
                snackbar.open({
                  message: `Sorry, there was an error loading contacts: ${error.message}. Please, try later.`,
                  status: 'error',
                  autoHideDuration: snackbarTimeout,
                });
              });
          }, 500),
        );
      });
    }

    const contactsPromise = new Promise((resolve) => {
      timeouts.push(
        setTimeout(() => {
          ContactsResource.getAll({
            organization: organizationId,
            owner: user.id,
            ...(inputValue ? { search: inputValue } : {}),
            limit: 100,
          })
            .then((response) => {
              let data = response.results;
              data = data.map((u) => ({
                label: u.name,
                value: u.id,
                isUser: false,
              }));
              resolve(data);
            })
            .catch((error) => {
              snackbar.open({
                message: `Sorry, there was an error loading contacts: ${error.message}. Please, try later.`,
                status: 'error',
                autoHideDuration: snackbarTimeout,
              });
            });
        }, 500),
      );
    });

    if (usersPromise)
      return Promise.all([usersPromise, contactsPromise]).then((values) =>
        values.flat(),
      );
    return contactsPromise;
  };

  const handleChange = (contact) => {
    if (contact) {
      if (contact.isUser) {
        history.push({
          pathname: `/sessions/${contact.value}`,
          state: { dashboardName: contact.label },
        });
      } else {
        history.push(`/clientFiling/${contact.value}/true`);
      }
    }
  };

  useEffect(() => {
    usersPromise = null;
  }, []);

  return (
    <Form.Group width="100%" mr={6} mb={0}>
      <AsyncSelect
        components={{ DropdownIndicator }}
        placeholder="Contact Search"
        classNamePrefix="lp-copy-sel"
        isClearable
        loadOptions={promiseOptions}
        onChange={handleChange}
      />
    </Form.Group>
  );
};

export default AsyncContactsSelect;
