import styled from '@emotion/styled';
import css from '@styled-system/css';
import { rgba } from 'polished';
import { themeGet } from '@styled-system/theme-get';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Button from '@bit/medicalwebexperts.mwe-ui.button';

const StyledDropdownItem = styled(Flex)((props) => {
  const color = themeGet('colors.secondary.dark')(props);
  const defaultColor = themeGet('colors.text')(props);
  return css({
    minWidth: 150,
    '&:hover': {
      backgroundColor: color ? rgba(color, 0.2) : rgba(defaultColor, 0.2),
    },
  });
});

const StyledButton = styled(Button)((props) => {
  return css({
    width: '100%',
    justifyContent: props.justifyContent ? props.justifyContent : 'start',
  });
});

export { StyledDropdownItem, StyledButton };
