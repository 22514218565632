import { useCallback, useReducer, useState } from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { ChatResource } from '../services';
import snackbarTimeout from '../constants/snackbarTimer';

const arrayReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return [...state, action.data];
    case 'set':
      return [...action.data];
    default:
      throw new Error();
  }
};

const activeChatDataReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return action.data;
    default:
      throw new Error();
  }
};

const useChat = (pubnub, user, organizationId, chatLocatorPath, snackbar) => {
  const [activeChat, setActiveChat] = useState();
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);
  const [unreadChats, setUnreadChats] = useState(0);

  const [chats, dispatchChats] = useReducer(arrayReducer, []);
  const [messages, dispatchMessages] = useReducer(arrayReducer, []);
  const [
    activeChatData,
    dispatchActiveChatData,
  ] = useReducer(activeChatDataReducer, { members: [], sessionId: '' });

  const handleSetUnreadChats = (data) => {
    const newChats = data.filter((c) => c.newMessage);
    setUnreadChats(newChats.length);
  };

  const updateNewChatMessage = useCallback(
    (channel, newMessage) => {
      const idx = chats.findIndex((c) => c.roomUuid === channel);
      const newChats = [...chats];
      newChats[idx].newMessage = newMessage;

      handleSetUnreadChats(newChats);
      dispatchChats({ type: 'set', data: newChats });
    },
    [chats],
  );

  const manageUnreadMetadata = useCallback(
    async (message) => {
      if (message) {
        await pubnub.publish({
          channel: `${message.channel}${user.chatId}`,
          message: message.timetoken,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pubnub, user],
  );

  const setChatInfo = (channel, chatsData) => {
    const idx = chatsData.findIndex((c) => c.roomUuid === channel);

    if (idx !== -1) {
      const activeChatMetadata = {
        members: chatsData[idx].participants,
        sessionId: chatsData[idx].meeting,
        owner: chatsData[idx].owner,
        ownerChatId: chatsData[idx].ownerChatId,
      };
      dispatchActiveChatData({ type: 'set', data: activeChatMetadata });
    }
  };

  const fetchChatMessages = useCallback(
    (channel, chatsData) => {
      if (pubnub) {
        try {
          setLoadingMessages(true);
          dispatchMessages({ type: 'set', data: [] });
          pubnub.fetchMessages(
            {
              channels: [channel],
              count: 100,
            },
            ({ error }, response) => {
              if (!error && response && response.channels) {
                const newMessages = response.channels[channel].reduce(
                  (results, message, idx) => {
                    if (response.channels[channel].length === idx + 1) {
                      manageUnreadMetadata(message);
                    }

                    const timestamp = momentTimezone(
                      moment.unix(message.timetoken / 10000000),
                    )
                      .tz(user.timezone)
                      .format('dddd, MMMM Do, hh:mm A');

                    if (message.message.file) {
                      const mm = message.message.file.name;
                      const m = {
                        text: mm,
                        type: 'self',
                        file: message.message.file.id,
                        timestamp,
                        sender: message.message.message.name,
                      };
                      if (message.uuid !== user.chatId) {
                        m.type = 'incoming';
                      }
                      results.push(m);
                    } else if (message.message.text) {
                      const m = {
                        text: message.message.text,
                        type: 'self',
                        timestamp,
                        sender: message.message.name,
                      };
                      if (message.uuid !== user.chatId) {
                        m.type = 'incoming';
                      }
                      results.push(m);
                    }
                    return results;
                  },
                  [],
                );
                dispatchMessages({ type: 'set', data: newMessages });
              }
              setLoadingMessages(false);
            },
          );
        } catch (error) {
          snackbar.open({
            message: `Sorry, there was an error fetching Chat messages: ${error.message}. Please, try later.`,
            status: 'error',
            autoHideDuration: snackbarTimeout,
          });
        } finally {
          setChatInfo(channel, chatsData);
        }
      }
    },
    [user, pubnub, snackbar, manageUnreadMetadata],
  );

  const handleSetActiveChat = useCallback(
    (channel) => {
      if (channel !== activeChat) {
        updateNewChatMessage(channel, false);
        setActiveChat(channel);
        fetchChatMessages(channel, chats);
      }
    },
    [activeChat, updateNewChatMessage, fetchChatMessages, chats],
  );

  const fetchSubscribedChats = useCallback(
    async (paramChatId, newChatNotification) => {
      if (pubnub && organizationId && user) {
        setLoadingChats(true);
        try {
          let activeChatControl;
          const { results } = await ChatResource.subscribed(organizationId);

          if (results.length === 0) {
            setActiveChat(null);
            activeChatControl = null;
            dispatchChats({ type: 'set', data: [] });
          } else {
            if (paramChatId) {
              const chatIdx = results.findIndex(
                (c) => c.meeting === +paramChatId,
              );

              if (chatIdx !== -1 && !newChatNotification) {
                // && !newChatNotification
                activeChatControl = results[chatIdx].roomUuid;
                setActiveChat(results[chatIdx].roomUuid);
                fetchChatMessages(results[chatIdx].roomUuid, results);
              } else if (!newChatNotification) {
                activeChatControl = results[0].roomUuid;
                setActiveChat(results[0].roomUuid);
                fetchChatMessages(results[0].roomUuid, results);
              }
            } else if (
              window.location.pathname.indexOf(chatLocatorPath) === -1
            ) {
              activeChatControl = null;
              setActiveChat(null);
            } else if (!newChatNotification) {
              activeChatControl = results[0].roomUuid;
              setActiveChat(results[0].roomUuid);
              fetchChatMessages(results[0].roomUuid, results);
            }

            const channelsMetadata = results.map(
              (c) => `${c.roomUuid}${user.chatId}`,
            );

            const channelsMetadataMessages = await pubnub.fetchMessages({
              channels: channelsMetadata,
              count: 1,
            });

            if (channelsMetadataMessages && channelsMetadataMessages.channels) {
              results.forEach((r, idxr) => {
                const channelLocator = `${r.roomUuid}${user.chatId}`;

                if (activeChatControl === r.roomUuid) {
                  results[idxr].newMessage = false;
                  handleSetUnreadChats(results);
                } else if (channelsMetadataMessages.channels[channelLocator]) {
                  pubnub.messageCounts(
                    {
                      channels: [r.roomUuid],
                      channelTimetokens: [
                        channelsMetadataMessages.channels[channelLocator][0]
                          .message,
                      ],
                    },
                    (s, count) => {
                      results[idxr].newMessage = count.channels[r.roomUuid] > 0;

                      handleSetUnreadChats(results);
                    },
                  );
                } else {
                  results[idxr].newMessage = false;
                }
              });
            }

            dispatchChats({ type: 'set', data: results });
          }
          const channels = results.map((c) => c.roomUuid);

          pubnub.unsubscribeAll();
          pubnub.subscribe({
            channels: [...channels, `notificationChannel${organizationId}`],
            withPresence: true,
          });
        } catch (error) {
          snackbar.open({
            message: `Sorry, there was an error fetching Chat Rooms: ${error.message}. Please, try later.`,
            status: 'error',
            autoHideDuration: snackbarTimeout,
          });
        } finally {
          setLoadingChats(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationId, pubnub, snackbar, user],
  );

  return {
    fetchSubscribedChats,
    chats,
    activeChat,
    messages,
    dispatchChats,
    handleSetActiveChat,
    loadingMessages,
    loadingChats,
    unreadChats,
    setUnreadChats,
    updateNewChatMessage,
    dispatchMessages,
    manageUnreadMetadata,
    activeChatData,
  };
};

export default useChat;
