import Resource from './base';

const baseUrl = '/api/availability';

// Calendar
const actions = (client) => ({
  getAvailability({ orgId, hostId, toDate, fromDate }) {
    return client.get(
      `${baseUrl}/host?organization=${orgId}&host=${hostId}&toDate=${toDate}&fromDate=${fromDate}`,
    );
  },

  getSlot(id, { orgId, hostId }) {
    return client.get(`${baseUrl}/${id}?organization=${orgId}&host=${hostId}`);
  },

  addTimeSlot(data) {
    return client.post(`${baseUrl}`, data);
  },

  updateTimeSlot(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  deleteTimeSlot({ slotId, orgId, hostId }) {
    return client.delete(
      `${baseUrl}/${slotId}?organization=${orgId}&host=${hostId}`,
    );
  },

  // Booking
  getBookingAvailability(params) {
    return client.get(`${baseUrl}/booking`, { params });
  },

  getTimeSlotsList({ hostId, orgId, fromDate, toDate }) {
    return client.get(
      `${baseUrl}?host=${hostId}&organization=${orgId}&fromDate=${fromDate}&toDate=${toDate}`,
    );
  },
});

export default Resource(baseUrl, actions);
