import Resource from './base';

const baseUrl = '/api/services';
const membersLocator = 'members';

const actions = (client) => ({
  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  delete(id, orgId) {
    return client.delete(`${baseUrl}/${id}?organization=${orgId}`);
  },

  manageMembers(id, data) {
    return client.post(
      `${baseUrl}/${id}/${membersLocator}${data.delete ? '?remove=true' : ''}`,
      data,
    );
  },
});

export default Resource(baseUrl, actions);
