import Resource from './base';

const baseUrl = '/api/organizations';

const actions = (client) => ({
  getBranding(subDomain) {
    return client.request({
      method: 'get',
      url: `${baseUrl}/theme?subDomain=${subDomain}`,
      headers: null,
    });
  },
  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  updateLogo(id, data) {
    return client.post(`${baseUrl}/${id}/logo`, data);
  },

  updateBranding(id, data) {
    return client.post(`${baseUrl}/${id}/branding_elements`, data);
  },

  updateSettings(id, data) {
    return client.post(`${baseUrl}/${id}/settings_features`, data);
  },

  updateSuperSettings(id, data) {
    return client.post(`${baseUrl}/${id}/super_settings`, data);
  },

  getSubscription(organization) {
    return client.get(`${baseUrl}/subscription`, {
      params: { organization },
    });
  },

  getInvoices(organization, params) {
    return client.get(`${baseUrl}/subscription_invoices`, {
      params: { organization, ...params },
    });
  },

  getApiKey(organization) {
    return client.get(`${baseUrl}/${organization}/api_key`);
  },

  enableApiKey(organization) {
    return client.post(`${baseUrl}/${organization}/enable_api_key`);
  },

  resetApiKey(organization) {
    return client.post(`${baseUrl}/${organization}/reset_api_key`);
  },
});

export default Resource(baseUrl, actions);
