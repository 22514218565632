import preset from '@bit/medicalwebexperts.mwe-ui.themes.preset';
import {
  MdLock,
  MdVisibility,
  MdVisibilityOff,
  MdPerson,
  MdTune,
  MdClose,
  MdAdd,
  MdArrowDropDown,
  MdMenu,
  MdDashboard,
  MdVoiceChat,
  MdQuestionAnswer,
  MdLibraryBooks,
  MdEventAvailable,
  MdInsertInvitation,
  MdGroup,
  MdHeadsetMic,
  MdAssignment,
  MdDescription,
  MdStars,
  MdExitToApp,
  MdCloudDownload,
  MdCloudUpload,
  MdSearch,
  MdEmail,
  MdMoreVert,
  MdEdit,
  MdBook,
  MdNotifications,
  MdDone,
  MdPriorityHigh,
  MdWarning,
  MdEvent,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdCompareArrows,
  MdFormatListBulleted,
  MdSettings,
  MdDrafts,
  MdSend,
  MdAttachFile,
  MdPalette,
  MdLightbulbOutline,
  MdVideocam,
  MdGetApp,
  MdDelete,
  MdContentCopy,
  MdRemoveCircleOutline,
  MdEventNote,
  MdDateRange,
  MdClear,
  MdPhone,
  MdCreditCard,
  MdSchedule,
} from 'react-icons/md';

import {
  FaSignal,
  FaStethoscope,
  FaHeartbeat,
  FaVideo,
  FaMicrophone,
  FaHeadphones,
  FaEye,
  FaUniversalAccess,
  FaCamera,
  FaExpandArrowsAlt,
  FaCompressArrowsAlt,
} from 'react-icons/fa';
import { GiEarthAmerica } from 'react-icons/gi';
import {
  AiFillMedicineBox,
  AiFillPushpin,
  AiFillSchedule,
  AiFillStar,
  AiFillGolden,
  AiTwotoneDiff,
  AiFillVideoCamera,
  AiFillFile,
  AiFillWechat,
  AiFillFileText,
  AiFillContacts,
  AiFillCustomerService,
} from 'react-icons/ai';

export default {
  ...preset,
  fonts: {
    ...preset.fonts,
    body:
      'Open Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading:
      'Open Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
  },
  typography: {
    h1: {
      fontFamily: 'heading',
      fontSize: '2xl',
      fontWeight: 'normal',
      lineHeight: 'normal',
      color: 'secondary.main',
    },
    h2: {
      fontFamily: 'heading',
      fontSize: '2xl',
      fontWeight: 'normal',
      lineHeight: 'normal',
      color: 'text',
    },
    h3: {
      fontFamily: 'body',
      fontSize: 'lg',
      fontWeight: 'bold',
      lineHeight: 'base',
      color: '#333333',
    },
    subtitle1: {
      fontFamily: 'body',
      fontSize: 'lg',
      fontWeight: 'medium',
      lineHeight: 'base',
      color: 'gray.700',
    },
    subtitle2: {
      fontFamily: 'body',
      fontSize: 'lg',
      fontWeight: 'bold',
      lineHeight: 'base',
      color: '#333333',
    },
    body1: {
      fontFamily: 'body',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    body2: {
      fontFamily: 'body',
      fontSize: 'sm',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    caption: {
      fontFamily: 'body',
      fontSize: 'xs',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    overline: {
      fontFamily: 'body',
      fontSize: '2xs',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    inherit: {},
  },
  colors: {
    ...preset.colors,
    text: '#444444',
    primary: {
      light: '#00CAFF',
      main: '#4C5664',
      dark: '#0093B8',
    },
    secondary: {
      light: '#BDBDBD',
      main: '#0093B8',
      dark: '#215371',
    },
    primaryButtonBackgroundColor: '#0093B8',
    sidebarHighlightColor: '#0093B8',
    sidebarHighlightTextColor: '#FFFFFF',
    primaryButtonTextColor: '#FFFFFF',
    secondaryButtonBackgroundColor: '#4b5563',
    secondaryButtonTextColor: '#FFFFFF',
    sidebarBackgroundColor: '#F7FAFC',
    sidebarTextColor: '#555',
    sidebarDropdown: '#FFFAFA',
    background: '#F9FBFD',
    cardBackground: '#F7FAFC',
    cardHeader: '#4B5663',
    darkGrey: '#4b5563',
  },
  sizes: {
    ...preset.sizes,
    sidebar: '240px',
  },
  icons: {
    ...preset.icons,
    lock: MdLock,
    person: MdPerson,
    search: MdSearch,
    visibility: MdVisibility,
    visibilityOff: MdVisibilityOff,
    close: MdClose,
    new: MdAdd,
    filter: MdTune,
    arrowDropdown: MdArrowDropDown,
    menu: MdMenu,
    dashboard: MdDashboard,
    videoConference: MdVoiceChat,
    chat: MdQuestionAnswer,
    books: MdLibraryBooks,
    eventAvailable: MdEventAvailable,
    invitation: MdInsertInvitation,
    group: MdGroup,
    headset: MdHeadsetMic,
    assignment: MdAssignment,
    description: MdDescription,
    stars: MdStars,
    exitApp: MdExitToApp,
    download: MdCloudDownload,
    upload: MdCloudUpload,
    email: MdEmail,
    vertMenu: MdMoreVert,
    edit: MdEdit,
    book: MdBook,
    notifications: MdNotifications,
    done: MdDone,
    warning: MdWarning,
    event: MdEvent,
    arrowUp: MdKeyboardArrowUp,
    arrowDown: MdKeyboardArrowDown,
    arrowLeft: MdKeyboardArrowLeft,
    arrowRight: MdKeyboardArrowRight,
    switch: MdCompareArrows,
    signal: FaSignal,
    earth: GiEarthAmerica,
    list: MdFormatListBulleted,
    settings: MdSettings,
    drafts: MdDrafts,
    priority: MdPriorityHigh,
    send: MdSend,
    attach: MdAttachFile,
    colors: MdPalette,
    bulb: MdLightbulbOutline,
    providers: AiFillMedicineBox,
    requests: AiFillPushpin,
    appointmentRequests: AiFillSchedule,
    accountServices: AiFillStar,
    serviceGroups: AiFillGolden,
    reports: AiTwotoneDiff,
    sessions: AiFillVideoCamera,
    sessionNotes: AiFillFile,
    chatSidebar: AiFillWechat,
    eDocs: AiFillFileText,
    contacts: AiFillContacts,
    support: AiFillCustomerService,
    videocam: MdVideocam,
    downloadFile: MdGetApp,
    delete: MdDelete,
    stethoscope: FaStethoscope,
    heartbeat: FaHeartbeat,
    video: FaVideo,
    microphone: FaMicrophone,
    headphones: FaHeadphones,
    eye: FaEye,
    universal: FaUniversalAccess,
    camera: FaCamera,
    copy: MdContentCopy,
    removeCircle: MdRemoveCircleOutline,
    expandAll: FaExpandArrowsAlt,
    collapseAll: FaCompressArrowsAlt,
    calendar: MdDateRange,
    clear: MdClear,
    calendarEvent: MdEventNote,
    phone: MdPhone,
    creditCard: MdCreditCard,
    clock: MdSchedule,
  },
};
