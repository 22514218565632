import Axios from 'axios';

const baseUrl = process.env.REACT_APP_ELECTRON_API_BASE_URL;

const Resource = {
  getCartconfig() {
    return Axios.get(`${baseUrl}cartconfig/options`);
  },
  enterMeeting(meeting, payload) {
    return Axios.post(`${baseUrl}meeting/enter/${meeting}`, payload);
  },
  getMeetingStatus() {
    return Axios.get(`${baseUrl}meeting/status`);
  },
  multiShow(buttonId) {
    return Axios.post(`${baseUrl}multi/show/${buttonId}`);
  },
};

export default Resource;
