import styled from '@emotion/styled';
import css from '@styled-system/css';
import Divider from '@bit/medicalwebexperts.mwe-ui.divider';

export default styled(Divider)(
  css({
    ml: -4,
    mr: -4,
  }),
);
