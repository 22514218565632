import React, { useCallback, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useHistory } from 'react-router-dom';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Spinner from '@bit/medicalwebexperts.mwe-ui.icon-button/dist/components/Spinner/Spinner';

import { useAuth } from '../../contexts/AuthContext';

const propTypes = {
  children: node.isRequired,
};

const LoggedOutLayout = ({ children }) => {
  const { isLoggedIn, verifyAuth } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const verifyAuthentication = useCallback(async () => {
    try {
      const loggedIn = isLoggedIn();
      if (loggedIn) {
        history.push('/');
      } else {
        const verificationResult = await verifyAuth();
        if (verificationResult) history.push('/');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoggedIn, verifyAuth, history]);

  useEffect(() => {
    verifyAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Container mt={4} mb={10}>
      {children}
    </Container>
  );
};

LoggedOutLayout.propTypes = propTypes;
export default LoggedOutLayout;
