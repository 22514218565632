import items from '../components/Layout/Sidebar/items';

const temporaryItems = ['Dashboard', 'Chat', 'Support'];

export default (user) => {
  const grp = user.groups.map((group) => group.name);
  if (user.isSuperuser) return items;
  if (user.fromAccessCode)
    return items.filter((item) => temporaryItems.includes(item.label));
  if (user.isTemporary)
    return items.filter((item) => temporaryItems.includes(item.label));
  if (user.isSuperuser) return items.filter((item) => item.to !== '/sessions');
  return items.filter((item) => item.groups.some((elem) => grp.includes(elem)));
};
