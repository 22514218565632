import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@bit/medicalwebexperts.mwe-ui.modal';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { useDown } from '@bit/medicalwebexperts.mwe-ui.utils.styled';

import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import { useNotificationContext } from '../../contexts/NotificationsContext';

const propTypes = {};

const StyledText = styled(Typography)(() =>
  css({
    color: '#707070',
    fontSize: 16,
  }),
);

const modalTimer = 5;

const RedirectModal = () => {
  const isMobile = useDown('lg');
  const { modal } = useNotificationContext();
  const [disabledButton, setDisabledButton] = useState(true);
  const [remainingTime, setRemainingTime] = useState(modalTimer);

  const enableButton = useCallback((timeout) => {
    if (timeout === 0) {
      setDisabledButton(false);
    } else {
      setTimeout(() => {
        setRemainingTime(timeout - 1);
        enableButton(timeout - 1);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (modal.visible) setDisabledButton(true);
    setRemainingTime(modalTimer);
    enableButton(modalTimer);
  }, [enableButton, modal]);

  return (
    <Modal
      state={modal}
      ariaLabel="Redirect Modal"
      withBackdrop
      tabIndex={0}
      top={isMobile ? 0 : '8vh'}
      width={isMobile ? '100%' : 'auto'}
      height={isMobile ? '100%' : 'auto'}
      maxHeight={!isMobile ? '90vh' : undefined}
      overflow="auto"
      hideOnClickOutside={false}
      hideOnEsc={false}
    >
      <Typography color="#215371" mt={6} variant="h2" textAlign="center">
        Updated SubDomain
      </Typography>
      <Modal.Body px={4}>
        <StyledText variant="body2" textAlign="center" paragraph>
          Your organization admin has updated your organization&apos;s
          subdomain.
        </StyledText>
        <StyledText variant="body2" textAlign="center" paragraph>
          Please wait while you are redirected to your organization&apos;s new
          URL.
        </StyledText>
      </Modal.Body>{' '}
      <Modal.Footer>
        <Flex
          justifyContent="center"
          flexDirection={isMobile ? 'column' : null}
        >
          <Button
            variantColor="secondary"
            ml={[0, 2, 4, null]}
            mt={[2, 2, 0, null]}
            disabled={disabledButton}
          >
            Accept {remainingTime > 0 && `(${remainingTime})`}
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

RedirectModal.propTypes = propTypes;
export default RedirectModal;
