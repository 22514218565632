import Resource from './base';

// ADMIN, HOST, CLINICAL ADMIN, SCHEDULER
const submissionsUrl = '/api/submissions';
// PARTICIPANTS
const submissionsParticipantUrl = '/api/submissions_participant';

const packetsUrl = '/api/packets';

const templatesUrl = '/api/documents';

const documentPacketsUrl = '/api/document_packets';

const getUrl = (isParticipant) =>
  isParticipant ? submissionsParticipantUrl : submissionsUrl;

const actions = (client) => ({
  // SUBMISSIONS
  listDocuments({ isParticipant, params }) {
    return client.get(`${getUrl(isParticipant)}`, { params });
  },

  getSubmission({ submissionId, organization, meeting, host, isParticipant }) {
    return client.get(`${getUrl(isParticipant)}/${submissionId}`, {
      params: {
        organization,
        meeting,
        host,
      },
    });
  },

  addSignature(isParticipant, id, data) {
    return client.put(
      `${getUrl(isParticipant)}/${id}${isParticipant ? '/sign' : ''}`,
      data,
    );
  },

  submitDocAndSignature(id, data) {
    return client.put(`${submissionsParticipantUrl}/${id}`, data);
  },

  deleteDocument({ id, organization, meeting, host }) {
    return client.delete(`${submissionsUrl}/${id}`, {
      params: {
        organization,
        meeting,
        host,
      },
    });
  },

  getClientFilingSubmission(params) {
    return client.get(`${submissionsParticipantUrl}/client_file`, { params });
  },

  // PACKETS
  listPacketsSession(params) {
    return client.get(`${packetsUrl}/packet_list`, { params });
  },

  listPackets({ params }) {
    return client.get(`${packetsUrl}`, { params });
  },

  getPacket({ id, organization }) {
    return client.get(`${packetsUrl}/${id}`, {
      params: {
        organization,
      },
    });
  },

  deletePacket({ id, organization }) {
    return client.delete(`${packetsUrl}/${id}`, {
      params: {
        organization,
      },
    });
  },

  createPacket(data) {
    return client.post(`${packetsUrl}`, data);
  },

  updatePacket(id, data) {
    return client.put(`${packetsUrl}/${id}`, data);
  },

  // TEMPLATES
  listTemplates({ params }) {
    return client.get(`${templatesUrl}`, { params });
  },

  getTemplate(template, organization) {
    return client.get(`${templatesUrl}/fields`, {
      params: {
        template,
        organization,
      },
    });
  },

  deleteTemplate({ id, organization }) {
    return client.delete(`${templatesUrl}/${id}`, {
      params: {
        organization,
      },
    });
  },

  createTemplate(data) {
    return client.post(`${templatesUrl}`, data);
  },

  updateTemplate(id, data) {
    return client.put(`${templatesUrl}/${id}`, data);
  },

  // DOCUMENT PACKETS
  getPacketList({ params }) {
    return client.get(`${documentPacketsUrl}`, { params });
  },

  linkTemplateWithPacket(data) {
    return client.post(`${documentPacketsUrl}`, data);
  },

  updateLinkedTemplate(templateId, data) {
    return client.put(`${documentPacketsUrl}/${templateId}`, data);
  },

  unlinkTemplate(packetId, organization, template) {
    return client.delete(`${documentPacketsUrl}/${packetId}`, {
      params: {
        organization,
        template,
      },
    });
  },

  getTemplateByPacket(packet, organization) {
    return client.get(`${documentPacketsUrl}/document_list`, {
      params: {
        organization,
        packet,
      },
    });
  },
});

export default Resource(submissionsUrl, actions);
