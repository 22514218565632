import React from 'react';
import { oneOfType, object } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { NavLink } from 'react-router-dom';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import BaseSidebar from '@bit/medicalwebexperts.mwe-ui.sidebar';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import List from '@bit/medicalwebexperts.mwe-ui.list';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import { useDown } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import { useAuth } from '../../../contexts/AuthContext';
import ZoomModal from '../../ZoomModal/ZoomModal';
import DesktopModal from '../../DesktopModal/DesktopModal';
import useIdleLogout from '../../../hooks/useIdleLogout';
import IdleLogoutModal from '../../IdleLogoutModal/IdleLogoutModal';
import Logo from '../../Logo/Logo';
import filterByPermission from '../../../utils/filterByPermission';
import { groups as GROUPS } from '../../../constants/groups';
import { useBranding } from '../../../contexts/BrandingContext';
import { useNotificationContext } from '../../../contexts/NotificationsContext';
import snackbarTimeout from '../../../constants/snackbarTimer';
import SidebarItemDropdown from './SidebarItemDropdown';
import AccountSwitcher from './AccountSwitcher';
import ProfileDropdown from '../../ProfileDropdown/ProfileDropdown';

const propTypes = {
  state: oneOfType([object]).isRequired,
};

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

const ListItem = styled(List.Item)(
  css({
    '&:hover': {
      bg: 'sidebarHighlightColor',
      color: 'sidebarHighlightTextColor',
    },
    '&.active': {
      bg: 'sidebarHighlightColor',
      color: 'sidebarHighlightTextColor',
      borderRightColor: 'sidebarHighlightColor',
    },
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightWidth: '6px',
    color: 'sidebarTextColor',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'md',
    fontWeight: 'medium',
    p: 4,
  }),
);

const sidebarStyles = {
  root: {
    border: 'none',
    bg: 'sidebarBackgroundColor',
  },
};

const Sidebar = (props) => {
  const snackbar = useSnackbarDispatch();
  const isMobile = useDown('lg');
  const { user, logout, organizationId } = useAuth();
  const { organizationLogo, organizationSettings } = useBranding();
  const mainItems =
    user &&
    filterByPermission(user)
      .filter((item) => item.sidebarLocation === 'main')
      .filter((item) =>
        organizationSettings?.chat ? item : item.label !== 'Chat',
      );
  const adminItems =
    user &&
    filterByPermission(user).filter((item) => item.sidebarLocation === 'admin');
  const settingsItems =
    user &&
    filterByPermission(user)
      .filter((item) => item.sidebarLocation === 'settings')
      .filter((item) =>
        organizationSettings?.sessionNotes
          ? item
          : item.label !== 'Session Notes',
      );
  const secondaryItems =
    user &&
    filterByPermission(user).filter(
      (item) => item.sidebarLocation === 'secondary',
    );
  const { unreadChats, pubnub } = useNotificationContext();
  const showSwitch =
    user &&
    user.groups.map((grp) => grp.name).includes(GROUPS.ADMINISTRATOR) &&
    user.organizations.length > 1;

  const handleLogout = async () => {
    try {
      await pubnub.publish({
        channel: `notificationChannel${organizationId}`,
        message: {
          resource: 'logout',
          user: user.id,
        },
      });
      pubnub.unsubscribeAll();
      await logout();
    } catch (error) {
      snackbar.open({
        message: `Sorry, there was an error signing out: ${error.message}. Please, try later.`,
        status: 'error',
        autoHideDuration: snackbarTimeout,
      });
    }
  };

  const [remainingSecs, isTimingOut] = useIdleLogout({
    countdowntimer: 60,
    idleTimer: 14,
    handleLogout,
    enabled: user ? !user.isDevice : true,
  });

  return (
    <BaseSidebar {...props} classes={sidebarStyles}>
      {isMobile && (
        <IconButton
          size="sm"
          boxShadow="btn"
          onClick={() => props.state.toggle()}
          mt={4}
          ml={4}
        >
          <Icon name="close" size={8} color="sidebarTextColor" />
        </IconButton>
      )}
      <Wrapper>
        {!isMobile && <Logo logo={organizationLogo} mt={6} />}
        <List as="nav" flexGrow="1" mt={isMobile ? 4 : 6}>
          {!!mainItems.length &&
            mainItems.map((item) => (
              <ListItem
                key={item.to}
                as={NavLink}
                to={item.to}
                disabled
                onClick={{ ...props }.state.hide}
                exact
              >
                {item.label}
                {item.label === 'Chat' && unreadChats > 0 && (
                  <Flex
                    borderRadius="full"
                    bg="danger"
                    alignItems="center"
                    justifyContent="center"
                    width="20px"
                    height="20px"
                    ml={4}
                  >
                    <Typography variant="caption" color="white">
                      {unreadChats}
                    </Typography>
                  </Flex>
                )}
              </ListItem>
            ))}
          {!!settingsItems.length && (
            <>
              <Typography variant="h5" color="sidebarTextColor" pl={2} py={2}>
                SETTINGS
              </Typography>
              <SidebarItemDropdown
                name="Features"
                items={settingsItems}
                onClick={() => props.state.hide()}
              />
            </>
          )}
          {!!adminItems.length && (
            <>
              <Typography variant="h5" color="sidebarTextColor" pl={2} py={2}>
                ADMIN
              </Typography>
              <SidebarItemDropdown
                name="Account Settings"
                items={adminItems}
                onClick={() => props.state.hide()}
              />
              {showSwitch && <AccountSwitcher />}
            </>
          )}
          {!!secondaryItems.length &&
            secondaryItems.map((item) => (
              <ListItem
                key={item.to}
                as={NavLink}
                to={item.to}
                onClick={() => props.state.hide()}
                exact
              >
                {item.label}
              </ListItem>
            ))}
          {user.isSuperuser && (
            <ListItem
              as="a"
              target="_blank"
              href={`https://${process.env.REACT_APP_CHARGIFY_SUBDOMAIN}.chargify.com/subscriptions`}
              onClick={() => props.state.hide()}
            >
              Billing Panel
            </ListItem>
          )}
          {isMobile && <ProfileDropdown onClick={() => props.state.hide()} />}
        </List>
      </Wrapper>
      <ZoomModal />
      <DesktopModal />
      <IdleLogoutModal status={isTimingOut} remainingSecs={remainingSecs} />
    </BaseSidebar>
  );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
