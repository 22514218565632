import { groups as GROUPS } from '../constants/groups';

export default (user) => {
  if (user) {
    const grp = user.groups.map((g) => g.name);
    if (user.isReseller) return 'isReseller';
    if (user.isSuperuser) return 'isSuperuser';
    if (user.isTemporary) return 'isTemporary';
    if (grp.includes(GROUPS.ADMINISTRATOR)) return 'isAdmin';
    if (grp.includes(GROUPS.CLINICAL_SUPERVISOR)) return 'isClinic';
    if (grp.includes(GROUPS.SCHEDULER)) return 'isScheduler';
    if (grp.includes(GROUPS.SESSION_HOST)) return 'isSessionHost';
    if (grp.includes(GROUPS.BILLING_MANAGER)) return 'isBillingManager';
    if (grp.length === 1 && grp.includes(GROUPS.SESSION_PARTICIPANT))
      return 'isSessionParticipant';
  }
  return false;
};
