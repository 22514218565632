import React, { useState, useContext, createContext, useCallback } from 'react';
import Cookies from 'js-cookie';
import { node } from 'prop-types';
import {
  AuthResource,
  OrganizationsResource,
  UsersResource,
} from '../services';
import { useBranding } from './BrandingContext';
import client from '../services/client';

export const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  isLoggedIn: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const propTypes = {
  children: node.isRequired,
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const [notificationPreferences, setNotificationPreferences] = useState(null);
  const [accessCode, setAccessCode] = useState(null);
  const [chatCode, setChatCode] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [render, setRender] = useState(true);
  const { organizationId: brandingOrganizationId } = useBranding();
  const { host, hostname } = window.location;
  const subDomain = host.split('.')[0];
  const refresh = client.$getRefreshToken();
  const location = hostname.split('.');
  location.shift();
  const domain = location.join('.');

  const isLoggedIn = () => user !== null && refresh;

  const login = async (credentials, fromBooking = false) => {
    await AuthResource.login(credentials);
    const userData = await AuthResource.me();
    if (fromBooking) userData.fromBooking = fromBooking;
    const matchingOrg = userData.organizations.filter(
      (org) => org.subDomain === subDomain,
    );

    if (userData.organizations.length && matchingOrg.length > 0) {
      setUser(userData);
      setNotificationPreferences(userData.notificationPreferences);
      setOrganizationId(matchingOrg[0].id);
    } else if (userData.isSuperuser || userData.isReseller) {
      const organization = await OrganizationsResource.get(
        credentials.organization,
      );

      if (userData.isSuperuser || organization.reseller === userData.id) {
        setUser(userData);
        setNotificationPreferences(userData.notificationPreferences);
        setOrganizationId(credentials.organization);
      } else {
        throw new Error();
      }
    } else {
      throw new Error();
    }
  };

  const logout = useCallback(async () => {
    try {
      if (user?.emergencyService) {
        await UsersResource.toggleEmergencyService({
          emergency_service: false,
        });
      }
      if (refresh) {
        setRender(false);
        await AuthResource.logout({ refresh });
        setUser(null);
        setNotificationPreferences(null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      if (chatCode) setChatCode('logout');
      if (accessCode) setAccessCode('logout');
      Cookies.remove('accessCode', { domain });
      client.$clearToken();
      setRender(true);
    }
  }, [user, refresh, chatCode, accessCode, domain]);

  const verifyAuth = useCallback(async () => {
    const access = Cookies.get('access', { domain });
    const accessCodeCookie = Cookies.get('accessCode', { domain });
    if (accessCodeCookie && !accessCode) {
      setAccessCode(accessCodeCookie);
    }

    try {
      if (!access && !user) {
        return false;
      }

      if (access && !user) {
        const userData = await AuthResource.me();
        if (accessCode) {
          const inFifteenMinutes = new Date(
            new Date().getTime() + 15 * 60 * 1000,
          );
          Cookies.set('accessCode', accessCode, {
            domain,
            expires: inFifteenMinutes,
          });
          userData.fromAccessCode = true;
        }

        if (userData) {
          const matchingOrg = userData.organizations.find(
            (org) => org.subDomain === subDomain,
          );

          if (userData.organizations.length && !!matchingOrg) {
            setUser(userData);
            setNotificationPreferences(userData.notificationPreferences);
            setOrganizationId(matchingOrg.id);
          } else if (userData.isSuperuser || userData.isReseller) {
            let id = brandingOrganizationId;
            if (!brandingOrganizationId) {
              const brand = await OrganizationsResource.getBranding(subDomain);
              if (brand) {
                const { id: newOrgId } = brand;
                id = newOrgId;
              } else {
                window.location.href = 'https://lti.trustvideo.com';
              }
            }
            const organization = await OrganizationsResource.get(id);

            if (userData.isSuperuser || organization.reseller === userData.id) {
              setUser(userData);
              setNotificationPreferences(userData.notificationPreferences);
              setOrganizationId(organization.id);
            } else {
              logout();
              return false;
            }
          } else {
            logout();
            return false;
          }

          return true;
        }
      }

      if (access && user) {
        const userData = await AuthResource.me();
        if (accessCode) userData.fromAccessCode = true;

        if (userData) {
          setUser(userData);
          setNotificationPreferences(userData.notificationPreferences);
        }
        return true;
      }
    } catch (error) {
      logout();
    } finally {
      setRender(true);
    }
    return false;
  }, [accessCode, logout, subDomain, user, brandingOrganizationId, domain]);

  const context = {
    user,
    organizationId,
    setOrganizationId,
    login,
    logout,
    isLoggedIn,
    verifyAuth,
    subDomain,
    accessCode,
    setAccessCode,
    setUser,
    notificationPreferences,
    setNotificationPreferences,
    chatCode,
    setChatCode,
  };

  return (
    <AuthContext.Provider value={context}>
      {render ? children : null}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = propTypes;

export default AuthProvider;
