import { groups } from '../../../constants/groups';

const {
  ADMINISTRATOR,
  BILLING_MANAGER,
  CLINICAL_SUPERVISOR,
  SCHEDULER,
  SESSION_HOST,
  SESSION_PARTICIPANT,
} = groups;

const items = [
  // MAIN
  {
    label: 'Dashboard',
    to: '/sessions',
    groups: [
      ADMINISTRATOR,
      SESSION_PARTICIPANT,
      SESSION_HOST,
      SCHEDULER,
      BILLING_MANAGER,
      CLINICAL_SUPERVISOR,
    ],
    sidebarLocation: 'main',
  },
  {
    label: 'Chat',
    to: '/chat',
    groups: [
      ADMINISTRATOR,
      SESSION_PARTICIPANT,
      SESSION_HOST,
      SCHEDULER,
      BILLING_MANAGER,
      CLINICAL_SUPERVISOR,
    ],
    sidebarLocation: 'main',
  },
  {
    label: 'Contacts',
    to: '/contacts',
    groups: [
      ADMINISTRATOR,
      SESSION_PARTICIPANT,
      SESSION_HOST,
      SCHEDULER,
      BILLING_MANAGER,
      CLINICAL_SUPERVISOR,
    ],
    sidebarLocation: 'main',
  },
  {
    label: 'Account Users',
    to: '/users',
    groups: [ADMINISTRATOR],
    sidebarLocation: 'main',
  },
  {
    label: 'Providers',
    to: '/providers',
    groups: [ADMINISTRATOR, SCHEDULER, CLINICAL_SUPERVISOR],
    sidebarLocation: 'main',
  },
  {
    label: 'Reports',
    to: '/reports',
    groups: [ADMINISTRATOR, CLINICAL_SUPERVISOR, BILLING_MANAGER],
    sidebarLocation: 'main',
  },
  // SETTINGS
  {
    label: 'Session Notes',
    to: '/sessionNotes',
    groups: [ADMINISTRATOR, SESSION_HOST, CLINICAL_SUPERVISOR],
    sidebarLocation: 'settings',
  },
  {
    label: 'Documents',
    to: '/documents',
    groups: [ADMINISTRATOR, SESSION_HOST, CLINICAL_SUPERVISOR],
    sidebarLocation: 'settings',
  },
  // ADMIN
  {
    label: 'Settings',
    to: '/settings',
    groups: [ADMINISTRATOR],
    sidebarLocation: 'admin',
  },
  // {
  //  label: 'Booking Page',
  //  to: '/booking',
  //  groups: [ADMINISTRATOR],
  //  sidebarLocation: 'admin',
  // },
  {
    label: 'Services',
    to: '/accountServices',
    groups: [ADMINISTRATOR],
    sidebarLocation: 'admin',
  },
  {
    label: 'Booking Page',
    to: '/serviceGroups',
    groups: [ADMINISTRATOR],
    sidebarLocation: 'admin',
  },
  {
    label: 'Branding',
    to: '/accounts/branding',
    groups: [ADMINISTRATOR],
    sidebarLocation: 'admin',
  },
  {
    label: 'Accept Payments',
    to: '/payments',
    groups: [ADMINISTRATOR, BILLING_MANAGER],
    sidebarLocation: 'admin',
  },
  {
    label: 'BAA',
    to: '/accounts/baa',
    groups: [ADMINISTRATOR],
    sidebarLocation: 'admin',
  },
  {
    label: 'Accounts',
    to: '/accounts',
    groups: [ADMINISTRATOR],
    sidebarLocation: 'secondary',
  },
  {
    label: 'Support',
    to: '/support',
    groups: [
      SESSION_PARTICIPANT,
      SESSION_HOST,
      CLINICAL_SUPERVISOR,
      BILLING_MANAGER,
      SCHEDULER,
    ],
    sidebarLocation: 'secondary',
  },
  {
    label: 'Billing',
    to: '/billing',
    groups: [ADMINISTRATOR, BILLING_MANAGER],
    sidebarLocation: 'admin',
  },
  // Other Routes
  {
    label: '',
    to: '/requests',
    groups: [SESSION_PARTICIPANT],
    sidebarLocation: null,
  },
  {
    label: '',
    to: '/clientFiling',
    groups: [
      ADMINISTRATOR,
      SESSION_HOST,
      SCHEDULER,
      CLINICAL_SUPERVISOR,
      SESSION_PARTICIPANT,
    ],
    sidebarLocation: null,
  },
  {
    label: '',
    to: '/profile',
    groups: [
      ADMINISTRATOR,
      SESSION_PARTICIPANT,
      SESSION_HOST,
      SCHEDULER,
      BILLING_MANAGER,
      CLINICAL_SUPERVISOR,
    ],
    sidebarLocation: null,
  },
];

export default items;
