import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { node } from 'prop-types';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import groupResources from '../services/resources/groups';
import {
  groupsLabel,
  groups as groupsConstants,
  dispatcherLabel,
} from '../constants/groups';
import { useBranding } from './BrandingContext';
import snackbarTimeout from '../constants/snackbarTimer';

const propTypes = {
  children: node.isRequired,
};

export const GroupsContext = createContext({
  groups: [],
});

export const useGroups = () => {
  return useContext(GroupsContext);
};

const GroupsProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);
  const snackbar = useSnackbarDispatch();
  const { organizationPlan } = useBranding();

  const getGroups = useCallback(async () => {
    try {
      const filteredGroups = [];
      const { results } = await groupResources.getAll();
      results.forEach((g) => {
        if (organizationPlan === 'V' && g.name === groupsConstants.SCHEDULER) {
          filteredGroups.push({
            name: g.name,
            label: dispatcherLabel,
            value: g.id,
          });
        } else {
          filteredGroups.push({
            name: g.name,
            label: groupsLabel[g.name],
            value: g.id,
          });
        }
      });
      setGroups(filteredGroups);
    } catch (error) {
      snackbar.open({
        message: `Sorry, there was an error fetching groups. Please, try later.`,
        status: 'error',
        autoHideDuration: snackbarTimeout,
      });
    }
  }, [organizationPlan, snackbar]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const context = {
    groups,
  };

  return (
    <GroupsContext.Provider value={context}>{children}</GroupsContext.Provider>
  );
};

GroupsProvider.propTypes = propTypes;

export default GroupsProvider;
