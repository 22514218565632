import Resource from './base';

const baseUrl = '/api/service_groups';

const actions = (client) => ({
  update(id, data) {
    return client.put(`${baseUrl}/${id}`, data);
  },

  delete(id, organizationId) {
    return client.delete(`${baseUrl}/${id}?organization=${organizationId}`);
  },
});

export default Resource(baseUrl, actions);
