import React, { useState, createContext, useContext } from 'react';
import { node } from 'prop-types';

export const ModalContext = createContext({
  desktopModal: null,
  setDesktopModal: () => null,
});

export const useModal = () => useContext(ModalContext);

const propTypes = {
  children: node.isRequired,
};

const ModalProvider = ({ children }) => {
  const [desktopModal, setDesktopModal] = useState(null);

  const context = { desktopModal, setDesktopModal };

  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
};

ModalProvider.propTypes = propTypes;

export default ModalProvider;
