export default (pathname) => {
  let path = pathname;
  let screenName = '';
  if (
    !Number.isNaN(
      Number(
        pathname.substring(pathname.length - 1, pathname.lastIndexOf('/') + 2),
      ),
    )
  ) {
    path = pathname.substring(0, pathname.lastIndexOf('/'));
  }
  switch (path) {
    case '/sessions':
      screenName = 'Dashboard';
      break;
    case '/sessions/sessionDetail':
      screenName = 'Session Detail';
      break;
    case '/chat':
      screenName = 'Chat';
      break;
    case '/contacts':
      screenName = 'Contacts';
      break;
    case '/users':
      screenName = 'Account Users';
      break;
    case '/reports/sessionUsage':
      screenName = 'Session Usage';
      break;
    case '/reports/geolocation':
      screenName = 'Geolocation';
      break;
    case '/reports/queues':
      screenName = 'Queues';
      break;
    case '/reports/logins':
      screenName = 'System Logins';
      break;
    case '/reports/payments':
      screenName = 'Transactions';
      break;
    case '/reports':
      screenName = 'Reports';
      break;
    case '/profile':
      screenName = 'My Profile';
      break;
    case '/sessionNotes':
      screenName = 'Session Notes';
      break;
    case '/documents/packets':
      screenName = 'Packets';
      break;
    case '/documents/view':
      screenName = 'Document View';
      break;
    case '/documents/preview':
      screenName = 'Document Preview';
      break;
    case '/documents/packets/documents_packets':
      screenName = 'Document Packets';
      break;
    case '/documents/templates':
      screenName = 'Templates';
      break;
    case '/documents/templates/preview':
      screenName = 'Template';
      break;
    case '/documents':
      screenName = 'Documents';
      break;
    case '/settings':
      screenName = 'Settings';
      break;
    case '/booking':
      screenName = 'Booking Page';
      break;
    case '/accountServices':
      screenName = 'Account Services';
      break;
    case '/serviceGroups':
      screenName = 'Service Groups';
      break;
    case '/serviceGroups/edit':
      screenName = 'Service Group';
      break;
    case '/providers':
      screenName = 'Providers';
      break;
    case '/branding':
      screenName = 'Branding';
      break;
    case '/payments':
      screenName = 'Accept Payments';
      break;
    case '/baa':
      screenName = 'BAA';
      break;
    case '/accounts':
      screenName = 'Accounts';
      break;
    case '/support':
      screenName = 'Support';
      break;
    default:
      screenName = 'Dashboard';
      break;
  }
  return screenName;
};
