import Resource from './base';

const baseUrl = '/api/payments';

const actions = (client) => ({
  getPaymentGatewayURL(params) {
    return client.get(`${baseUrl}/auth_url`, { params });
  },

  revokeStripeAccount(data) {
    return client.post(`${baseUrl}/revoke_account`, data);
  },

  createPayment(data) {
    return client.post(`${baseUrl}/create_payment`, data);
  },

  confirmPayment(data) {
    return client.post(`${baseUrl}/confirm_payment`, data);
  },

  refundPayment(pk, data) {
    return client.post(`${baseUrl}/${pk}/refund_payment`, data);
  },

  cancelPaypal(data) {
    return client.post(`${baseUrl}/cancel_paypal_onboarding`, data);
  },
});

export default Resource(baseUrl, actions);
